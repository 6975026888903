import { IPartialCSS } from "partnerbuilder-common";
import {
    BackgroundStyles,
    PaddingStyles,
    DisplayStyles,
    MarginStyles,
    ColorStyles,
    FontStyles
} from "./GroupsStyles.static";

export const QRScannerStyles: IPartialCSS<any> = {
    ...FontStyles,
    ...ColorStyles,
    ...MarginStyles,
    ...DisplayStyles,
    ...PaddingStyles,
    ...BackgroundStyles
};
