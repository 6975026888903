import { FC } from "react";
import { ILang } from "../../../../Interfaces/ILang.type";
import { useGlobal } from "../../../../Provider/GlobalContext.provider";
import { useTranslation } from "react-i18next";
import { NavbarLinkStyles } from "../Static/NavbarLinkStyles.static";
import { ParseComponentStyle } from "partnerbuilder-common";

interface INavbarLangPickerProps {
    navbarBackground?: string;
}

export const NavbarLangPicker: FC<INavbarLangPickerProps> = (props) => {
    const { i18n } = useTranslation<ILang>();
    const { widgets, config, size, languageChange } = useGlobal();

    const { navbarLangPicker } = widgets.Navbar.settings;
    let langStyles = ParseComponentStyle(navbarLangPicker, NavbarLinkStyles, size) || {};

    const getLangButton = (lang: string): JSX.Element => {
        const cls: string[] = ["navbar__languages--button"];
        let clonedStyles = { ...langStyles };

        if (lang === i18n.language) {
            cls.push("active-language");
            if (!clonedStyles.color) {
                clonedStyles.color = config.main_btn_bg_color;
            }
        }

        return (
            <button
                className={cls.join(" ")}
                style={clonedStyles}
                onClick={() => languageChange(lang)}
            >
                {lang.toUpperCase()}
            </button>
        );
    };

    return (
        <div className="navbar__languages">
            {getLangButton(config.primary_language)}
            {config.secondary_language && (
                <>
                    <span className="navbar__languages--separator">/</span>
                    {getLangButton(config.secondary_language)}
                </>
            )}
        </div>
    );
};
