import { ModelConstructor, IDynamicObject } from "xa-generics";

export class StartPageModel {
    constructor(rawData?: IDynamicObject<any>) {
        if (!rawData) return;
        const data = rawData as StartPageMData;
        ModelConstructor(data, this);
    }

    restaurant_id: string = "";
    partner_id: string = "";
    created_at: string = "";
    updated_at: string = "";
}

//If you add public methods, you may want to replace the empty string with them!
type StartPageMData = Partial<InstanceType<typeof StartPageModel>>;
