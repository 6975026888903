import { ISection } from "../Interfaces/IDomTypes.interface";
import { IServerDom } from "partnerbuilder-common";
import { ModelConstructor, IDynamicObject } from "xa-generics";

export class DomModel implements IServerDom<IDynamicObject<any>[]> {
    constructor(rawData?: IDynamicObject<any>) {
        if (!rawData) return;
        const data = rawData as DomMData;
        ModelConstructor(data, this);

        this.dom = typeof data.dom === "string" ? JSON.parse(data.dom || "[]") : data.dom;
    }

    partner_id: string = "";
    page_id: string = "";
    dom: ISection[] = [];
    created_at: string = "";
    updated_at: string = "";
}

//If you add public methods, you may want to replace the empty string with them!
type DomMData = Partial<Omit<InstanceType<typeof DomModel>, "dom">> & {
    dom: string;
};
