import { IWidgets } from "partnerbuilder-common";
import { v4 as uuid } from "uuid";
import { IWidgetTypes } from "../Interfaces/IWidgets.type";
import { ModelConstructor, IDynamicObject } from "xa-generics";

export class WidgetsModel implements IWidgets<IWidgetTypes, IWidgetTypes> {
    constructor(rawData?: IDynamicObject<any>) {
        if (!rawData) return;
        const data = rawData as WidgetsMData;
        ModelConstructor(data, this);

        if (typeof data.widgets === "string") this.widgets = JSON.parse(data.widgets);
        else if (typeof data.widgets === "object") this.widgets = data.widgets;
        else this.widgets = {} as IWidgetTypes;

        this.generateDefaultIfEmpty("Footer");
        this.generateDefaultIfEmpty("Navbar");
    }

    created_at: string = "";
    widgets!: IWidgetTypes;
    partner_id: string = "";
    updated_at: string = "";

    private generateDefaultIfEmpty(key: keyof IWidgetTypes, content?: any): void {
        if (!this.widgets[key]) {
            this.widgets[key] = {
                settings: content || {},
                type: key,
                uuid: uuid()
            } as never;
        }
    }

    get Transform(): IWidgetTypes {
        return this.widgets;
    }
}

//If you add public methods, you may want to replace the empty string with them!
type WidgetsMData = Partial<InstanceType<typeof WidgetsModel>>;
