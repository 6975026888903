import { IPartialCSS } from "partnerbuilder-common";
import {
    AlignStyles,
    BackgroundStyles,
    BorderRadiusStyles,
    BorderStyles,
    BoxShadowSyles,
    HeightStyles,
    MarginStyles,
    OpacityStyles,
    PaddingStyles,
    WidthStyles
} from "./GroupsStyles.static";

export const ImageTypeStyles: IPartialCSS<any> = {
    ...PaddingStyles,
    ...MarginStyles,
    ...BackgroundStyles,
    ...BorderStyles,
    ...BorderRadiusStyles,
    ...OpacityStyles,
    ...BoxShadowSyles,
    ...WidthStyles,
    ...HeightStyles,
    ...AlignStyles
};
