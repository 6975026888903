import { IPartialCSS } from "partnerbuilder-common";
import {
    BackgroundImageStyles,
    BackgroundStyles,
    PaddingStyles,
    DisplayStyles,
    HeightStyles,
    MarginStyles,
    ColorStyles,
    WidthStyles,
    FontStyles
} from "./GroupsStyles.static";

export const SelectorStyles: IPartialCSS<any> = {
    ...FontStyles,
    ...ColorStyles,
    ...WidthStyles,
    ...HeightStyles,
    ...MarginStyles,
    ...DisplayStyles,
    ...PaddingStyles,
    ...BackgroundStyles,
    ...BackgroundImageStyles
};
