import Config from "./Config/Packages.config";
import Cookies from "js-cookie";
import ReactDOM from "react-dom";
import { COOKIES } from "./Static/COOKIES.static";
import { StrictMode } from "react";
import { AXIOS_NAMES } from "./Static/AxiosNames.static";
import { LANGUAGE_LIST } from "./Static/LanguageList.static";
import { BrowserRouter } from "react-router-dom";
import { PartnerRoutes } from "./Partner.routes";
import { useTranslation } from "react-i18next";
import { GlobalContextProvider } from "./Provider/GlobalContext.provider";
import { IAllowedLanguages, IDynamicObject, SetCookie, axios, useDidMount } from "xa-generics";
import "./Index.css";

axios.initAxios("", AXIOS_NAMES.SELF, "hu");
const defaultLang: IAllowedLanguages = (() => {
    const value = (Cookies.get(COOKIES.lang)?.slice(0, 2) as IAllowedLanguages) || "hu";
    if (LANGUAGE_LIST.indexOf(value) === -1) {
        const date = new Date();
        date.setFullYear(date.getFullYear() + 10);
        SetCookie(COOKIES.lang, "hu", date, "Strict");
        return "hu";
    }
    return value;
})();
Config(defaultLang);

const Main = (props: { CLIENT_URL: string }) => {
    const { i18n } = useTranslation();
    useDidMount(() => {
        const dataScript = document.getElementById("BODY_DATA_SCRIPT");
        if (dataScript) dataScript.remove();
    });

    return (
        <BrowserRouter>
            <GlobalContextProvider CLIENT_URL={props.CLIENT_URL}>
                <PartnerRoutes key={`${i18n.language}-lang-key`} />
            </GlobalContextProvider>
        </BrowserRouter>
    );
};

export const InitApp = (ENV_VARS: IDynamicObject) => {
    if (process.env.NODE_ENV === "development") {
        const REST_API_URL = ENV_VARS.REST_API_URL;
        axios.initAxios(REST_API_URL, AXIOS_NAMES.DEFAULT, defaultLang);
    }
    const ONEMIN_BACKEND = ENV_VARS.ONEMIN_BACKEND;
    axios.initAxios(ONEMIN_BACKEND, AXIOS_NAMES.ONEMIN, defaultLang);
    ReactDOM.render(
        <StrictMode>
            <Main CLIENT_URL={ENV_VARS.CLIENT_URL} />
        </StrictMode>,
        document.getElementById("root")
    );
};

if (process.env.NODE_ENV === "production") {
    const ENV_VARS = JSON.parse(decodeURIComponent(window.ENV_VARS));
    InitApp(ENV_VARS);
} else {
    axios
        .getInstance(AXIOS_NAMES.SELF)
        .get("/api/rest")
        .then((res) => {
            InitApp(res.data);
        })
        .catch((error) => {
            console.error("ERROR", error);
            ReactDOM.render(
                <StrictMode>
                    <Main CLIENT_URL={""} />
                </StrictMode>,
                document.getElementById("root")
            );
        });
}
