import { Send } from "@carbon/icons-react";
import { ILang } from "../../../../Interfaces/ILang.type";
import { axios } from "xa-generics";
import { useForm } from "../../../UI/UseForm/UseForm.provider";
import { GlobalDAO } from "../../../../DAO/GlobalData.dao";
import { TextInput } from "../../../UI/Inputs/TextInput.view";
import { useGlobal } from "../../../../Provider/GlobalContext.provider";
import { AXIOS_NAMES } from "../../../../Static/AxiosNames.static";
import { FC, useMemo } from "react";
import { SearchInput } from "../../../UI/Inputs/SearchInput.view";
import { StreetModel } from "../../../../Models/Street.model";
import { RegionModel } from "../../../../Models/Region.model";
import { GlobalButton } from "../../../UI/GlobalButton/GlobalButton.view";
import { RawSelectInput } from "../../../UI/Inputs/RawSelectInput.view";
import { useTranslation } from "react-i18next";
import { RestaurantModel } from "partnerbuilder-common";
import { AddressFormModel } from "./Model/AddressForm.model";
import Cookies from "js-cookie";

interface ISelectorFormProps {
    selectableRestaurants: RestaurantModel[];
}

export const SelectorForm: FC<ISelectorFormProps> = (props) => {
    const { t } = useTranslation<ILang>();
    const { PLATFORM, handleAddressChange, filteredRestaurants } = useGlobal();

    const restaurants = props.selectableRestaurants;
    const form = useForm<AddressFormModel, true>({
        editor: (() => {
            const data = Cookies.get("address_form");
            if (data) return new AddressFormModel(JSON.parse(data || "{}"));
            else return new AddressFormModel();
        })(),
        saveInCookie: "address_form",
        initialRules: {
            restaurant_id: true
        },
        fieldHooks: {
            city: (state) => {
                handleAddressChange(state, (rest) => {
                    form.handleChange("restaurant_id", rest.id);
                });
            },
            street: (state) => {
                handleAddressChange(state, (rest) => {
                    form.handleChange("restaurant_id", rest.id);
                });
            },
            zipcode: (state) => {
                handleAddressChange(state, (rest) => {
                    form.handleChange("restaurant_id", rest.id);
                });
            },
            house_number: (state) => {
                handleAddressChange(state, (rest) => {
                    form.handleChange("restaurant_id", rest.id);
                });
            },
            delivery_region_id: (state, value, option) => {
                if (option) {
                    state.restaurant_id = option.restaurant_id;
                    const rest = props.selectableRestaurants.find(
                        (rest) => rest.id === option.restaurant_id
                    );
                    state.tmp_region_name = option.name;
                    if (rest) {
                        state.delivery_method = rest.delivery_method;
                    }
                }
                return state;
            },
            tmp_region_name: (state) => {
                state.street_id = "";
                state.tmp_street_name = "";
                state.street = "";
            },
            street_id: (state, value, option) => {
                if (option) {
                    if (option.postcode) state.zipcode = option.postcode;
                    state.tmp_street_name = option.name;
                    return state;
                }
            }
        }
    });

    const KIND = useMemo(() => {
        let isRegionBased = false;
        for (const rest of props.selectableRestaurants) {
            if (form.editor.restaurant_id) {
                if (form.editor.restaurant_id === rest.id) return rest.delivery_method;
            } else {
                if (rest.delivery_method === "priced_street_based") return "priced_street_based";
                if (rest.delivery_method === "region_based") isRegionBased = true;
            }
        }
        if (isRegionBased) return "region_based";
        return "gps_based";
    }, [props.selectableRestaurants, form.editor.restaurant_id]);

    const findRegion = async (value: string): Promise<RegionModel[]> => {
        if (!value) return [];
        return GlobalDAO.loadRegions(value);
    };

    const findStreet = async (value: string): Promise<StreetModel[]> => {
        if (!value) return [];
        return GlobalDAO.loadStreets(
            form.editor.restaurant_id,
            form.editor.delivery_region_id,
            value
        );
    };

    const classes = ["selector__address-form"];
    if (KIND === "gps_based") classes.push("--WITH_ZIPCODE");

    return (
        <form
            className={classes.join(" ")}
            onSubmit={form.handleSubmit(
                (values) => {
                    const rest = restaurants.find((rest) => rest.id === values.restaurant_id);
                    values.domain = rest?.domain;
                    values.PLATFORM = PLATFORM;
                    axios
                        .getInstance(AXIOS_NAMES.SELF)
                        .post<string>("/api/address", values)
                        .then((res) => res.data)
                        .then((redirectUrl) => {
                            const aTag = document.createElement("a");
                            aTag.href = redirectUrl;
                            aTag.rel = "noreferrer noopener";
                            aTag.target = "_self";
                            aTag.style.width = "1px";
                            aTag.style.height = "1px";
                            aTag.style.overflow = "hidden";
                            aTag.style.opacity = "0";
                            document.body.appendChild(aTag);
                            aTag.click();
                        })
                        .catch((error) => console.error(error));
                },
                (er) => console.error(er)
            )}
            autoComplete={"off"}
            autoSave={"off"}
        >
            <h3 className="selector__address-form--title">{t<ILang>("delivery")}</h3>

            {KIND === "gps_based" && (
                <TextInput
                    isNumeric
                    id={"zipcode"}
                    className={"zipcode"}
                    autoComplete={false}
                    value={form.editor.zipcode}
                    errors={form.fieldErrors}
                    onChange={form.handleChange}
                />
            )}
            {KIND !== "gps_based" ? (
                <SearchInput
                    required
                    optionIdKey={"id"}
                    queryAfterCharCount={1}
                    optionNameKey={"name"}
                    altId={"tmp_region_name"}
                    id={"delivery_region_id"}
                    errors={form.fieldErrors}
                    queryTextValue={findRegion}
                    onChange={form.handleChange}
                    className={"delivery_region_id"}
                    textValue={form.editor.tmp_region_name}
                    placeholder={t<ILang>("look_for_region")}
                    onTextChange={form.handleChange}
                    value={form.editor.delivery_region_id}
                />
            ) : (
                <TextInput
                    id={"city"}
                    className={"city"}
                    autoComplete={false}
                    value={form.editor.city}
                    errors={form.fieldErrors}
                    onChange={form.handleChange}
                />
            )}
            {KIND === "priced_street_based" ? (
                <SearchInput
                    required
                    id={"street_id"}
                    optionIdKey={"id"}
                    className={"street_id"}
                    queryAfterCharCount={2}
                    altId={"tmp_street_name"}
                    errors={form.fieldErrors}
                    queryTextValue={findStreet}
                    onChange={form.handleChange}
                    value={form.editor.street_id}
                    optionNameKey={"combinedName"}
                    onTextChange={form.handleChange}
                    textValue={form.editor.tmp_street_name}
                    placeholder={t<ILang>("look_for_street")}
                />
            ) : (
                <TextInput
                    id={"street"}
                    className={"street"}
                    autoComplete={false}
                    errors={form.fieldErrors}
                    value={form.editor.street}
                    onChange={form.handleChange}
                />
            )}
            <TextInput
                id={"house_number"}
                className={"house_number"}
                autoComplete={false}
                value={form.editor.house_number}
                errors={form.fieldErrors}
                onChange={form.handleChange}
            />

            <TextInput
                id={"floor"}
                className={"floor"}
                autoComplete={false}
                value={form.editor.floor}
                errors={form.fieldErrors}
                onChange={form.handleChange}
            />

            <TextInput
                id={"door"}
                className={"door"}
                autoComplete={false}
                value={form.editor.door}
                errors={form.fieldErrors}
                onChange={form.handleChange}
            />

            <TextInput
                id={"bell"}
                className={"bell"}
                autoComplete={false}
                value={form.editor.bell}
                errors={form.fieldErrors}
                onChange={form.handleChange}
            />

            <RawSelectInput
                required
                id={"restaurant_id"}
                className={"restaurant_id"}
                labelText={"Selector"}
                options={filteredRestaurants.length > 0 ? filteredRestaurants : restaurants}
                value={form.editor.restaurant_id}
                onChange={form.handleChange}
                errors={form.fieldErrors}
            />

            <GlobalButton
                // isDisabled={!form.editor.restaurant_id}
                type={"submit"}
                RightIcon={<Send />}
                className={"submit-button"}
            >
                {t<ILang>("order")}
            </GlobalButton>
        </form>
    );
};
