import { ModelConstructor, IDynamicObject } from "xa-generics";

export class AddressFormModel {
    constructor(rawData?: IDynamicObject<any>) {
        if (!rawData) return;
        const data = rawData as AddressFormMData;
        ModelConstructor(data, this);
    }

    delivery_region_id: string = "";
    tmp_region_name: string = "";
    street_id: string = "";
    tmp_street_name: string = "";
    street: string = "";
    zipcode: string = "";
    city: string = "";
    house_number: string = "";
    floor: string = "";
    door: string = "";
    bell: string = "";
    restaurant_id: string = "";
    domain: string = "";
    delivery_method: "gps_based" | "region_based" | "priced_street_based" = "gps_based";
    PLATFORM: string = window.PLATFORM;

    public get toAPIData(): IDynamicObject<any> | null {
        const data: IDynamicObject<any> = {};
        if (!this.city || !this.street || !this.house_number) {
            return null;
        }
        data.city = this.city;
        data.street = this.street;
        data.premise_number = this.house_number;
        if (this.zipcode) data.postcode = this.zipcode;
        return data;
    }
}

//If you add public methods, you may want to replace the empty string with them!
type AddressFormMData = Partial<InstanceType<typeof AddressFormModel>>;
