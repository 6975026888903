import { IPartialCSS } from "partnerbuilder-common";
import {
    BackgroundImageStyles,
    BackgroundStyles,
    DisplayStyles,
    PaddingStyles
} from "./GroupsStyles.static";

export const SectionStyles: IPartialCSS<any> = {
    ...DisplayStyles,
    ...PaddingStyles,
    ...BackgroundStyles,
    ...BackgroundImageStyles
};
