import { ILang } from "../../../../Interfaces/ILang.type";
import { IQRData } from "../../../Widgets/Navbar/Interfaces/IQRData.interface";
import { useState } from "react";
import { QrScanner } from "@yudiel/react-qr-scanner";
import { useGlobal } from "../../../../Provider/GlobalContext.provider";
import { IDomElement } from "../../../../Interfaces/IDomTypes.interface";
import { GlobalButton } from "../../../UI/GlobalButton/GlobalButton.view";
import { useTranslation } from "react-i18next";
import { QRScannerStyles } from "../../Static/QRScannerStyles.static";
import { IQRScannerContent } from "../../Interfaces/IQRScannerComponent.interface";
import { QrCode, Restaurant } from "@carbon/icons-react";
import { ParseComponentStyle } from "partnerbuilder-common";
import Modal from "../../../UI/Modal/Modal.view";

export interface IDomTextProps extends IDomElement<IQRScannerContent> {}

export const QRScannerView: React.FC<IDomTextProps> = (props) => {
    const { t } = useTranslation<ILang>();
    const { size, showOnlySelector } = useGlobal();
    let style = ParseComponentStyle(props.content, QRScannerStyles, size);

    const { restaurants, PLATFORM, CLIENT_URL } = useGlobal();
    const [showReader, setShowReader] = useState<boolean>(false);
    const [result, setResult] = useState<null | IQRData>(null);

    const extractTableId = (text: string): void => {
        let restaurant_id = "";
        let domain = "";
        let table_id = "";

        const tableMatch = text.match(/\/qr-table\/([^/?]+)/);
        if (tableMatch && tableMatch.length === 2) {
            const tableId = tableMatch[1];
            if (!isNaN(parseInt(tableId))) table_id = tableId;
        }

        const domainMatch = text.match(/^(?:https?:\/\/)?([a-zA-Z0-9.-]+)(?:\/|$)/);
        if (domainMatch && domainMatch[1]) {
            for (const rest of restaurants) {
                if (rest.domain === domainMatch[1]) {
                    restaurant_id = rest.id;
                }
            }
            domain = domainMatch[1];
        }

        if (domain && table_id && restaurant_id) {
            if (process.env.NODE_ENV === "development") {
                text = `http://localhost:3700/qr-table/${table_id}`;
            }
            setResult({ restaurant_id, table_id, domain, qr_url: text });
        } else {
            setResult(null);
        }
    };

    const generateURL = (result: IQRData): string => {
        if (PLATFORM === "browser") {
            return result.qr_url;
        }
        let url = `${CLIENT_URL}/apps/${PLATFORM}/${result.restaurant_id}?tableId=${result.table_id}&lastSelectedRestId=${result.restaurant_id}`;
        return url;
    };

    if (showOnlySelector) {
        if (!style) style = {};
        style.paddingTop = "2rem";
    }

    return (
        <div id={props.uuid} className="dom__qr" style={style}>
            <GlobalButton
                onClick={() => setShowReader(!showReader)}
                RightIcon={<QrCode size={28} />}
            >
                {t<ILang>("scan_qr_code")}
            </GlobalButton>

            {showReader && (
                <Modal
                    isPassive
                    heading={t<ILang>("table_qr")}
                    onClose={() => setShowReader(false)}
                >
                    {!result ? (
                        <>
                            <h4>{t<ILang>("table_qr_scan")}</h4>
                            <br />
                            <QrScanner
                                viewFinderBorder={4}
                                onDecode={extractTableId}
                                onError={(error) => console.error(error)}
                            />
                        </>
                    ) : (
                        <>
                            <h4>{t<ILang>("qr_redirect1")}</h4>
                            <br />
                            <a
                                target={"_self"}
                                rel="noreferrer noopener"
                                href={generateURL(result)}
                                style={{ width: "100%" }}
                            >
                                <GlobalButton
                                    RightIcon={<Restaurant size={28} />}
                                    style={{
                                        width: "100%",
                                        minHeight: "6rem",
                                        fontSize: "2rem"
                                    }}
                                >
                                    {t<ILang>("qr_redirect2")}
                                </GlobalButton>
                            </a>
                        </>
                    )}
                </Modal>
            )}
        </div>
    );
};
