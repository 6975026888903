import { Send } from "@carbon/icons-react";
import { ILang } from "../../../../Interfaces/ILang.type";
import { useForm } from "../../../UI/UseForm/UseForm.provider";
import { TextArea } from "../../../UI/Inputs/TextArea.view";
import { IContact } from "../../../../Interfaces/IContact.interface";
import { GlobalDAO } from "../../../../DAO/GlobalData.dao";
import { TextInput } from "../../../UI/Inputs/TextInput.view";
import { useGlobal } from "../../../../Provider/GlobalContext.provider";
import { IDomElement } from "../../../../Interfaces/IDomTypes.interface";
import { GlobalButton } from "../../../UI/GlobalButton/GlobalButton.view";
import { FloatingError } from "xa-error-with-lang";
import { InlineLoading } from "../../../UI/InlineLoading/InlineLoading.view";
import { useTranslation } from "react-i18next";
import { RawSelectInput } from "../../../UI/Inputs/RawSelectInput.view";
import { IError, ILoading } from "xa-generics";
import { ContactFormStyles } from "../../Static/ContactFormStyles.static";
import { useMemo, useState } from "react";
import { IContactFormContent } from "../../Interfaces/IContactFormComponent.interface";
import { FormatPhoneNumber, FormStyleSeparator, ParseComponentStyle } from "partnerbuilder-common";
import Modal from "../../../UI/Modal/Modal.view";

export interface IContactFormProps extends IDomElement<IContactFormContent> {}

export const ContactForm: React.FC<IContactFormProps> = (props) => {
    const { t } = useTranslation<ILang>();
    const { size, images, restaurants } = useGlobal();

    const [showSentModal, setShowSentModal] = useState<boolean>(false);
    const [loading, setLoading] = useState<ILoading>(false);
    const [error, setError] = useState<IError>(null);

    const form = useForm<IContact, true>({
        editor: {
            name: "",
            phone_number: "",
            email: "",
            message: "",
            restaurant_id: ""
        },
        initialRules: {
            name: true,
            phone_number: true,
            email: true,
            message: true,
            restaurant_id: true
        }
    });

    const style = useMemo(
        () => ParseComponentStyle(props.content, ContactFormStyles, size),
        [props.content, size]
    );
    const imageFile = images[props.content.src];
    const { body, text, image } = useMemo(() => FormStyleSeparator(style), [style]);

    return (
        <>
            <div id={props.uuid} style={body} className="dom__form dom__contact-form">
                <div className="dom__contact-form--left">
                    <h1 style={text} className={"dom__form--title dom__contact-form--title"}>
                        {t<ILang>("message_us")}
                    </h1>
                    <form
                        onSubmit={form.handleSubmit((values) => {
                            setLoading(
                                GlobalDAO.sendContact(values.restaurant_id, values)
                                    .then(() => setShowSentModal(true))
                                    .catch((error) => setError(error))
                                    .finally(() => setLoading(false))
                            );
                        })}
                        className="dom__form--element dom__contact-form--form"
                    >
                        <RawSelectInput
                            required
                            id={"restaurant_id"}
                            options={restaurants}
                            labelText={"Selector"}
                            errors={form.fieldErrors}
                            onChange={form.handleChange}
                            value={form.editor.restaurant_id}
                        />
                        <TextInput
                            id={"name"}
                            onChange={form.handleChange}
                            value={form.editor.name}
                            errors={form.fieldErrors}
                        />
                        <TextInput
                            id={"phone_number"}
                            onChange={(id, value) =>
                                form.handleChange(id, FormatPhoneNumber(value))
                            }
                            value={form.editor.phone_number}
                            errors={form.fieldErrors}
                        />
                        <TextInput
                            id={"email"}
                            onChange={form.handleChange}
                            value={form.editor.email}
                            errors={form.fieldErrors}
                        />
                        <TextArea
                            id={"message"}
                            onChange={form.handleChange}
                            value={form.editor.message}
                            errors={form.fieldErrors}
                        />
                        <GlobalButton
                            RightIcon={loading ? <InlineLoading isAlternate /> : Send}
                            type={"submit"}
                        >
                            {t<ILang>("send")}
                        </GlobalButton>
                    </form>
                </div>
                <div className="dom__form--image dom__contact-form--right">
                    <img
                        src={
                            imageFile
                                ? imageFile.url
                                : `${process.env.PUBLIC_URL}/contact_us_placeholder.avif`
                        }
                        className={"img dom__contact-form--image"}
                        alt={"Contact form"}
                        draggable={false}
                        style={image}
                    />
                </div>
            </div>
            <FloatingError error={error} resetError={() => setError(null)} />
            {showSentModal && (
                <Modal
                    isPassive
                    onClose={() => {
                        setShowSentModal(false);
                        if (error) setError(null);
                    }}
                >
                    {t<ILang>("message_sent")}
                </Modal>
            )}
        </>
    );
};
