import { map } from "lodash";
import { ISection } from "../../../../Interfaces/IDomTypes.interface";
import { useGlobal } from "../../../../Provider/GlobalContext.provider";
import { SectionStyles } from "../../Static/SectionStyles.static";
import { ParseComponentStyle } from "partnerbuilder-common";
import DomColumn from "../Column/DomColumn.view";

export interface IDomSectionProps {
    section: ISection;
    index: number;
}

const DomSection: React.FC<IDomSectionProps> = (props) => {
    const { size, images } = useGlobal();
    const content = props.section as ISection;

    const colCount: number = props.section.elements.length;
    const classes: string[] = ["section"];
    classes.push(`section-grid-col-${colCount}`);
    classes.push(`section-${size}`);
    if (props.section.isSpecial) classes.push("special-section");

    let style = ParseComponentStyle(props.section.content, SectionStyles, size, images);

    return (
        <>
            <div className={classes.join(" ")} id={content.uuid} style={style}>
                {map(props.section.elements, (column) => (
                    <DomColumn column={column} key={column.uuid} />
                ))}
            </div>
        </>
    );
};

export default DomSection;
