import { FC } from "react";
import { ILang } from "../../../../Interfaces/ILang.type";
import { useGlobal } from "../../../../Provider/GlobalContext.provider";
import { IDomElement } from "../../../../Interfaces/IDomTypes.interface";
import { useTranslation } from "react-i18next";
import { ImageTypeStyles } from "../../Static/ImageTypeStyle.static";
import { IImageTypeContent } from "../../Interfaces/IImageComponent.interface";
import { ImageStyleSeparator, ParseComponentStyle } from "partnerbuilder-common";

interface IDomImageProps extends IDomElement<IImageTypeContent> {}

export const DomImage: FC<IDomImageProps> = (props) => {
    const { i18n } = useTranslation<ILang>();
    const { size, images } = useGlobal();

    const currentLang = i18n.language;

    let style = ParseComponentStyle(props.content, ImageTypeStyles, size);
    const text = props.content?.[currentLang]?.text || "";
    const image = images[props.content.src];
    const { container, tag } = ImageStyleSeparator(style || {});

    return (
        <div id={props.uuid} style={container} className={"dom__image-container"}>
            <img
                src={image?.url || ""}
                alt={text}
                className={"dom__image-container--image"}
                style={tag}
            />
        </div>
    );
};
