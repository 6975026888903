import { ILoading } from "xa-generics";
import { useGlobal } from "../../../Provider/GlobalContext.provider";
import { IPartialCSS } from "partnerbuilder-common";

export interface IInlineLoadingProps {
    isAlternate?: boolean;
    isAbsolute?: boolean;
    loading?: ILoading;
    inContainer?: boolean;
}

export const InlineLoading: React.FC<IInlineLoadingProps> = (props) => {
    const classes: string[] = ["inline-loading"];
    const { config } = useGlobal();
    const style: IPartialCSS<any> = {};
    const t = "transparent";

    if (config.main_btn_bg_color) {
        style.borderColor = `${config.main_btn_bg_color} ${t} ${t} ${t}`;
    }
    if (config.secondary_btn_bg_color && props.isAlternate) {
        style.borderColor = `${config.secondary_btn_bg_color} ${t} ${t} ${t}`;
    }

    const getContainer = (child: any): JSX.Element => {
        const wrapperClasses: string[] = ["loading-container"];
        if (props.isAbsolute) wrapperClasses.push("absolute-loading");

        if (props.inContainer || typeof props.loading === "object" || props.isAbsolute) {
            return (
                <div className={wrapperClasses.join(" ")}>
                    {child}
                    {props.loading &&
                        typeof props.loading === "object" &&
                        "reason" in props.loading && (
                            <div className="loading-message">{props.loading.reason}</div>
                        )}
                </div>
            );
        } else return <>{child}</>;
    };

    return getContainer(
        <div className={classes.join(" ")}>
            <div className="lds-ring">
                <div style={style}></div>
                <div style={style}></div>
                <div style={style}></div>
                <div style={style}></div>
            </div>
        </div>
    );
};
