import { map } from "lodash";
import { ILang } from "../../../../Interfaces/ILang.type";
import { useGlobal } from "../../../../Provider/GlobalContext.provider";
import { IDomElement } from "../../../../Interfaces/IDomTypes.interface";
import { useTranslation } from "react-i18next";
import { IBulletListContent } from "../../Interfaces/IBulletListComponent.interface";
import { ParseComponentStyle } from "partnerbuilder-common";
import { BulletListTypeStyles } from "../../Static/BulletListStyles.static";

export interface IDomBulletListProps extends IDomElement<IBulletListContent> {}

const DomBulletList: React.FC<IDomBulletListProps> = (props) => {
    const { i18n } = useTranslation<ILang>();
    const { size } = useGlobal();
    const content = props.content as IBulletListContent;

    let style = ParseComponentStyle(content, BulletListTypeStyles, size);

    const renderList = (): JSX.Element | null => {
        if (!content?.list) return null;

        return (
            <ul>
                {map(content?.list, (item, i) => (
                    <li key={`${item.id}-${i}-list`}>{item.value[i18n.language]}</li>
                ))}
            </ul>
        );
    };

    return (
        <div style={style} id={props.uuid} className={"dom__bullet-list"}>
            {renderList()}
        </div>
    );
};

export default DomBulletList;
