import { FC } from "react";
import { ILang } from "../../../../Interfaces/ILang.type";
import { AppleHU } from "../../../UI/SVG/AppleHU";
import { AppleEN } from "../../../UI/SVG/AppleEN";
import { useGlobal } from "../../../../Provider/GlobalContext.provider";
import { IDomElement } from "../../../../Interfaces/IDomTypes.interface";
import { AppStoreStyles } from "../../Static/AppStoreStyles.static";
import { useTranslation } from "react-i18next";
import { IAppStoreContent } from "../../Interfaces/IAppStoreComponent.interface";
import { ParseComponentStyle } from "partnerbuilder-common";
import AndroidHU from "../../../../Assets/gp_badge_hu.webp";
import AndroidEN from "../../../../Assets/gp_badge_en.webp";

interface IAppStoreProps extends IDomElement<IAppStoreContent> {}

export const AppStore: FC<IAppStoreProps> = (props) => {
    const { i18n } = useTranslation<ILang>();
    const { size } = useGlobal();
    const lang = i18n.language;

    const style = ParseComponentStyle(props.content, AppStoreStyles, size);

    const androidLogo = lang === "hu" ? AndroidHU : AndroidEN;
    const iosLogo =
        lang === "hu" ? <AppleHU className={"svg"} s={40} /> : <AppleEN s={40} className={"svg"} />;

    return (
        <div style={style} id={props.uuid} className={"dom__app-store"}>
            <a
                href={props.content.android}
                target="_blank"
                rel="noreferrer noopener"
                className="dom__app-store--icon dom__app-store--android"
            >
                <img src={androidLogo} alt={"Android logo"} className={"image"} />
            </a>
            <a
                href={props.content.ios}
                target="_blank"
                rel="noreferrer noopener"
                className="dom__app-store--icon dom__app-store--ios"
            >
                {iosLogo}
            </a>
        </div>
    );
};
