import { Menu } from "@carbon/icons-react";
import { useState } from "react";
import { useGlobal } from "../../../../Provider/GlobalContext.provider";
import { NavbarLinks } from "./NavbarLinks.view";
import { NavbarStyles } from "../Static/NavbarStyles.static";
import { NavbarLangPicker } from "./NavbarLang.view";
import { NavbarNameAndLogo } from "./NavbarNameAndLogo.view";
import { ParseComponentStyle } from "partnerbuilder-common";

export interface IWidgetNavbarProps {}

const WidgetNavbar: React.FC<IWidgetNavbarProps> = (props) => {
    const [isNavbarOpened, setIsNavbarOpened] = useState<boolean>(false);
    const { size, currentFont, widgets, config, showOnlySelector } = useGlobal();

    const classes: string[] = ["widget", "navbar", `navbar-${size}`, currentFont];
    const { navbarStyle, isNameHidden, navbarLogo } = widgets.Navbar.settings;

    const style = ParseComponentStyle(navbarStyle, NavbarStyles, size);

    if (isNavbarOpened) classes.push("navbar-opened");
    if (config.is_dark_theme) classes.push("dark-navbar");

    if (!isNameHidden && navbarLogo) classes.push("--LOGO_AND_NAME");
    else if (isNameHidden) classes.push("--WITH_LOGO");
    else classes.push("--WITH_NAME");

    if (showOnlySelector) classes.push("--APP_NAVBAR");

    return (
        <nav style={style} className={classes.join(" ")}>
            <NavbarNameAndLogo />
            {!showOnlySelector && <NavbarLinks />}

            <NavbarLangPicker navbarBackground={style?.backgroundColor} />
            {!showOnlySelector && (
                <div className="navbar__meta" onClick={() => setIsNavbarOpened(!isNavbarOpened)}>
                    <Menu size={28} />
                </div>
            )}

            {!showOnlySelector && (size === "xs" || size === "sm") && (
                <>
                    <div
                        className="navbar__backdrop"
                        onClick={() => setIsNavbarOpened(false)}
                    ></div>
                    <div className="navbar__sidemenu" onClick={(e) => e.stopPropagation()}>
                        <NavbarNameAndLogo />
                        <NavbarLinks setIsNavbarOpened={setIsNavbarOpened} />
                        <NavbarLangPicker navbarBackground={style?.backgroundColor} />
                    </div>
                </>
            )}
        </nav>
    );
};

export default WidgetNavbar;
