import { IPartialCSS } from "partnerbuilder-common";
import {
    FontStyles,
    ColorStyles,
    TextShadowSyles
} from "../../../DomMapper/Static/GroupsStyles.static";

export const FooterNameStyles: IPartialCSS<any> = {
    ...TextShadowSyles,
    ...ColorStyles,
    ...FontStyles
};
