import { useMemo } from "react";
import { useGlobal } from "../../../Provider/GlobalContext.provider";
import { PageDataModel } from "../../../Models/PageData.model";
import DomSection from "../DomComponents/Section/Section.view";

export interface IDomMapperViewProps {
    page: PageDataModel;
}

export const DomMapperView: React.FC<IDomMapperViewProps> = (props) => {
    const { showOnlySelector } = useGlobal();

    const domNodes: JSX.Element[] = useMemo(() => {
        if (!showOnlySelector) {
            return props.page.elements.dom.map((section, index) => (
                <DomSection key={section.uuid} section={section} index={+index} />
            ));
        } else {
            const list: JSX.Element[] = [];
            const length = props.page.elements.dom.length;
            for (let index = 0; index < length; index++) {
                const section = props.page.elements.dom[index];
                if (!section.isSpecial) continue;
                if (section.elements.length === 0 || section.elements[0].elements.length === 0)
                    continue;
                if (
                    section.elements[0].elements[0].type !== "Selector" &&
                    section.elements[0].elements[0].type !== "QRScanner"
                )
                    continue;

                list.push(<DomSection key={section.uuid} section={section} index={+index} />);
            }
            return list;
        }
    }, [props.page.elements.dom, showOnlySelector]);
    return <>{domNodes}</>;
};
