import { ILang } from "../../../../Interfaces/ILang.type";
import { useGlobal } from "../../../../Provider/GlobalContext.provider";
import { IDomElement } from "../../../../Interfaces/IDomTypes.interface";
import { TextTypeStyles } from "../../Static/TextTypeStyles.static";
import { useTranslation } from "react-i18next";
import { ITextTypeContent } from "../../Interfaces/ITextTypeComponents.interface";
import { ParseComponentStyle } from "partnerbuilder-common";
import HTMLParser from "html-react-parser";

export interface IDomTextProps extends IDomElement<ITextTypeContent> {}

const DomText: React.FC<IDomTextProps> = (props) => {
    const { i18n } = useTranslation<ILang>();
    const currentLang = i18n.language;

    const { size } = useGlobal();
    const content = props.content as ITextTypeContent;

    let style = ParseComponentStyle(content, TextTypeStyles, size);
    const text = content?.[currentLang]?.text || "";

    const htmlText = text.replace(/\n/g, "<br />");

    return (
        <div id={props.uuid} className="dom__text" style={style}>
            {HTMLParser(htmlText)}
        </div>
    );
};

export default DomText;
