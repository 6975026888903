import { FC } from "react";
import { map } from "lodash";
import { ISetState } from "xa-generics";
import { useGlobal } from "../../../../Provider/GlobalContext.provider";
import { NavbarLinkElement } from "./NavbarLink.element";

interface INavbarLinksProps {
    setIsNavbarOpened?: ISetState<boolean>;
}

export const NavbarLinks: FC<INavbarLinksProps> = (props) => {
    const { widgets } = useGlobal();
    const { navbarLinks } = widgets.Navbar.settings;

    return (
        <div className="navbar__links">
            {map(navbarLinks, (link, index) => (
                <NavbarLinkElement
                    link={link}
                    key={`${index}-link-${link.index}`}
                    setIsNavbarOpened={props.setIsNavbarOpened}
                />
            ))}
        </div>
    );
};
