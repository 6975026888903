import { FC } from "react";
import { ILang } from "../../../../Interfaces/ILang.type";
import { NavLink } from "react-router-dom";
import { useGlobal } from "../../../../Provider/GlobalContext.provider";
import { INavbarLink } from "../Interfaces/INavbarWidget.interface";
import { ILinkContent } from "../../../DomMapper/Interfaces/ILinkComponent.interface";
import { useTranslation } from "react-i18next";
import { NavbarLinkStyles } from "../Static/NavbarLinkStyles.static";
import { ParseComponentStyle } from "partnerbuilder-common";
import { ISetState } from "xa-generics";

interface INavbarLinkElementProps {
    link: INavbarLink;
    setIsNavbarOpened?: ISetState<boolean>;
}

export const NavbarLinkElement: FC<INavbarLinkElementProps> = ({ link, setIsNavbarOpened }) => {
    const { t, i18n } = useTranslation<ILang>();
    const lang = i18n.language;

    const { widgets, size, pages } = useGlobal();
    const { navbarLink } = widgets.Navbar.settings;

    const linkStyles = ParseComponentStyle(navbarLink, NavbarLinkStyles, size);

    const data = link[lang] as ILinkContent;
    const routeName = data?.routeName || t<ILang>("none");

    if (link.isExternal) {
        const url = data?.url || "#";
        return (
            <a
                href={url}
                style={linkStyles}
                className={"navbar__links--element"}
                onClick={(e) => {
                    if (setIsNavbarOpened) setIsNavbarOpened(false);
                }}
                {...(link.openOnNewPage ? { target: "_blank", rel: "noreferrer noopener" } : {})}
            >
                {routeName}
            </a>
        );
    }

    const getLinkData = (urlAsPageId: string): Partial<ILinkContent> => {
        const defaultData = { routeName, url: "#" };
        if (!urlAsPageId) return defaultData;

        const page = pages.find((page) => page.page_id === urlAsPageId);
        if (!page) return defaultData;
        const seo = page.getSeo(i18n.language);
        if (!seo || !seo.url) return defaultData;

        return {
            routeName: data?.routeName || seo.title || t<ILang>("none"),
            url: seo.url || "#"
        };
    };

    const linkData = getLinkData(data.url);

    return (
        <NavLink
            to={linkData.url!}
            style={linkStyles}
            className={"navbar__links--element"}
            onClick={(e) => {
                if (setIsNavbarOpened) setIsNavbarOpened(false);
            }}
        >
            {linkData.routeName}
        </NavLink>
    );
};
