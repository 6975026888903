import { ModelConstructor, IDynamicObject } from "xa-generics";

export class RegionModel {
    constructor(rawData?: IDynamicObject<any>) {
        if (!rawData) return;
        const data = rawData as RegionMData;
        ModelConstructor(data, this);
    }

    id: string = "";
    name: string = "";
    restaurant_id: string = "";
    price: number = 0;
    name_sec_lang: string = "";
    archived_at: string = "";
    active: boolean = false;
    min_order_price: number = 0;
    threshold_price: number = 0;
    price_under_threshold: number = 0;
    courier_price: string = "";
    created_at: string = "";
    updated_at: string = "";
}

//If you add public methods, you may want to replace the empty string with them!
type RegionMData = Partial<InstanceType<typeof RegionModel>>;
