import { IPage, IPageTypes } from "partnerbuilder-common";
import { IDynamicObject } from "xa-generics";
import { SeoModel } from "partnerbuilder-common";

export abstract class PageModel implements IPage<string, SeoModel> {
    constructor(seos: IDynamicObject<any>[], platform?: string, partner_id?: string) {
        if (seos instanceof Array) {
            this.seos = seos.map((seo) => new SeoModel(seo, platform, partner_id));
        }
    }

    partner_id: string = "";
    page_id: string = "";
    page_type: IPageTypes = "";
    name: string = "";
    is_active: boolean = true;
    is_main_page: boolean = false;
    updated_at: string = "";
    created_at: string = "";

    seos: SeoModel[] = [];

    //Custom props
    type: "Page" = "Page";

    public getSeo(lang: string): SeoModel {
        const seo = this.seos.find((seo) => seo.lang === lang)!;
        return seo;
    }

    public getSeoByUrl(pathname: string): SeoModel | null {
        const seo = this.seos.find((seo) => seo.url === pathname);
        return seo || null;
    }
}
