import {
    ISizes,
    ImageModel,
    ConfigModel,
    PartnerModel,
    FONT_OPTIONS,
    GetWindowSize,
    RestaurantModel
} from "partnerbuilder-common";
import {
    axios,
    IError,
    ILoading,
    SetCookie,
    Objectify,
    useTimeout,
    useDidMount,
    IDynamicObject,
    useMountWithTriggers
} from "xa-generics";
import { Context, createContext, useState, useContext } from "react";
import { AddressFormModel } from "../Components/DomMapper/DomComponents/Selector/Model/AddressForm.model";
import { IGlobalContext } from "../Interfaces/IGlobalContext.interface";
import { useTranslation } from "react-i18next";
import { StartPageModel } from "../Models/StartPage.model";
import { FloatingError } from "xa-error-with-lang";
import { PageDataModel } from "../Models/PageData.model";
import { WidgetsModel } from "../Models/Widgets.model";
import { AXIOS_NAMES } from "../Static/AxiosNames.static";
import { setBoolean } from "xa-generics";
import { GlobalDAO } from "../DAO/GlobalData.dao";
import { COOKIES } from "../Static/COOKIES.static";
import Loading from "../Components/UI/Loading/Loading.view";

/**
 * ## GlobalContext
 */
const GlobalContext: Context<IGlobalContext> = createContext<IGlobalContext>(null as any);

GlobalContext.displayName = "GlobalContext";

type Constructable = { new (...args: any[]): any };
type IPlatform = "browser" | "android" | "ios";

interface IGlobalContextProviderProps {
    CLIENT_URL: string;
}

/**
 * ## Global context provider component
 *
 */
export const GlobalContextProvider: React.FC<IGlobalContextProviderProps> = (props) => {
    const { i18n } = useTranslation();
    const [error, setError] = useState<IError>(null);
    const [loading, setLoading] = useState<ILoading>(
        process.env.NODE_ENV === "development" ? true : false
    );
    const [size, setSize] = useState<ISizes>(GetWindowSize());
    const { setTm } = useTimeout();

    const deconvert = <T extends Constructable, isArray extends boolean, K extends InstanceType<T>>(
        rawData: any,
        model: T,
        isArr: isArray
    ): isArray extends true ? K[] : K => {
        if (!rawData) return isArr ? [] : (null as any);
        const data = JSON.parse(decodeURIComponent(rawData));
        if (isArr) {
            if (data instanceof Array) return data.map((item) => new model(item)) as any;
            else return [] as any;
        } else return new model(data) as InstanceType<T>;
    };

    const [filteredRestaurants, setFilteredRestaurants] = useState<RestaurantModel[]>([]);
    const [widgets, setWidgets] = useState<WidgetsModel | null>(
        deconvert(window.widgets, WidgetsModel, false)
    );
    const [pages, setPages] = useState<PageDataModel[]>(
        deconvert(window.pages, PageDataModel, true)
    );
    const [config, setConfig] = useState<ConfigModel | null>(
        (() => {
            if (!window.config) return null;
            const rawData = JSON.parse(decodeURIComponent(window.config));
            const model = new ConfigModel(rawData);
            GlobalDAO.setPID(model.partner_id);
            return model;
        })()
    );
    const [images, setImages] = useState<IDynamicObject<ImageModel>>(() => {
        if (!window.images) return {};
        const data = JSON.parse(decodeURIComponent(window.images));
        return data instanceof Array
            ? Objectify(
                  data.map((item) => new ImageModel(item)),
                  "image_id"
              )
            : {};
    });
    const [partner, setPartner] = useState<PartnerModel | null>(
        deconvert(window.partner, PartnerModel, false)
    );
    const [restaurants, setRestaurants] = useState<RestaurantModel[]>(
        deconvert(window.restaurants, RestaurantModel, true).sort((a, b) =>
            a.name.localeCompare(b.name)
        )
    );
    const [startPages, setStartPages] = useState<StartPageModel[]>(
        deconvert(window.startPages, StartPageModel, true)
    );
    const [currentFont, setCurrentFont] = useState<string>("");
    const [PLATFORM] = useState<IPlatform>(window.PLATFORM || process.env.REACT_APP_PLATFORM);
    const [showOnlySelector] = useState<boolean>(
        setBoolean(window.showOnlySelector || process.env.REACT_APP_SHOW_ONLY_SELECTOR)
    );

    const handleAddressChange = (
        state: AddressFormModel,
        cb: (autoSelected: RestaurantModel) => void
    ): void => {
        setTm(
            () => {
                const data = state.toAPIData;
                if (!data) return setFilteredRestaurants([]);
                GlobalDAO.loadRestaurantsByAddress(data)
                    .then((matchingRestaurants) => {
                        const selectable: RestaurantModel[] = [];
                        for (const rest of matchingRestaurants) {
                            for (const startPage of startPages) {
                                if (startPage.restaurant_id === rest.id) {
                                    selectable.push(rest);
                                }
                            }
                        }
                        if (selectable.length > 0) {
                            cb(selectable[0]);
                        }
                        setFilteredRestaurants(
                            selectable.sort((a, b) => a.name.localeCompare(b.name))
                        );
                    })
                    .catch((error) => console.error(error));
            },
            500,
            "find-restaurant"
        );
    };

    const languageChange = (newLang: string): void => {
        const date = new Date();
        date.setFullYear(date.getFullYear() + 10);
        SetCookie(COOKIES.lang, newLang, date, "Strict");
        axios.setLanguage(newLang, AXIOS_NAMES.DEFAULT);
        axios.setLanguage(newLang, AXIOS_NAMES.ONEMIN);
        i18n.changeLanguage(newLang);
    };
    const setSizeOnResize = (): void => setSize(GetWindowSize());

    useDidMount(() => {
        window.addEventListener("resize", setSizeOnResize, { passive: true });
        if (process.env.NODE_ENV === "development") {
            setLoading(
                Promise.all([
                    GlobalDAO.loadWidget(),
                    GlobalDAO.loadPages(),
                    GlobalDAO.loadConfig(),
                    GlobalDAO.loadImages(),
                    GlobalDAO.loadPartner(),
                    GlobalDAO.loadRestaurants(),
                    GlobalDAO.loadStarPages()
                ])
                    .then((dataset) => {
                        setWidgets(dataset[0]);
                        setPages(dataset[1]);
                        setConfig(dataset[2]);
                        setImages(Objectify(dataset[3], "image_id"));
                        setPartner(dataset[4]);
                        setRestaurants(dataset[5].sort((a, b) => a.name.localeCompare(b.name)));
                        setStartPages(dataset[6]);

                        const font = FONT_OPTIONS.find(
                            (opt) => opt.id === dataset[2].font_family_cdn
                        );
                        setCurrentFont(font?.class || FONT_OPTIONS[0].class);
                    })
                    .catch((error) => setError(error))
                    .finally(() => setLoading(false))
            );
        } else {
            const font = FONT_OPTIONS.find((opt) => opt.id === config?.font_family_cdn);
            setCurrentFont(font?.class || FONT_OPTIONS[0].class);
        }
        return () => {
            window.removeEventListener("resize", setSizeOnResize);
        };
    });

    useMountWithTriggers(() => {
        if (pages.length === 0) return;
    }, [pages]);

    useMountWithTriggers(() => {
        let element: HTMLStyleElement | null = null;
        if (config) {
            element = document.createElement("style");
            const main = config.main_btn_bg_color || "#ff0154";
            const secondary = config.secondary_btn_bg_color || "#999";
            const font = FONT_OPTIONS.find((opt) => opt.id === config?.font_family_cdn);
            element.innerHTML = `::-webkit-scrollbar-track {
                background-color: ${secondary || "#999"} !important;
            }
            ::-webkit-scrollbar-thumb {
                background-color: ${main || "#ff0154"} !important;
            }
            textarea:focus, input:focus, input:focus-visible {
                outline-color: ${main} !important;
            }
            a:focus, a:focus-within, a:focus-visible, a:hover {
                color: ${main} !important;
                outline-color: transparent;
                outline-style: none;
            }
            .atag-link:focus, .atag-link:focus-within, .atag-link:focus-visible, .atag-link:hover {
                background-color: ${main} !important;
                color: ${config.main_btn_color || "auto"} !important;
                outline-color: transparent;
                outline-style: none;
            }
            * {
                font-family: ${font?.font};
            }
            `;
            document.head.appendChild(element);
        }
        return () => {
            if (element) element.remove();
        };
    }, [config]);

    if (!widgets || !config || !partner) {
        return <Loading loading={loading} isAbsolute size={"lg"} />;
    }

    return (
        <GlobalContext.Provider
            value={{
                CLIENT_URL: props.CLIENT_URL,
                widgets: widgets.Transform,
                handleAddressChange,
                filteredRestaurants,
                showOnlySelector,
                languageChange,
                restaurants,
                currentFont,
                startPages,
                PLATFORM,
                partner,
                loading,
                images,
                config,
                pages,
                error,
                size
            }}
        >
            <Loading loading={loading} isAbsolute size={"sm"} />
            <FloatingError error={error} resetError={() => setError(null)} />
            {props.children}
        </GlobalContext.Provider>
    );
};

export const useGlobal = (): IGlobalContext => useContext(GlobalContext);
