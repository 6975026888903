import { map } from "lodash";
import { IColumn } from "../../../../Interfaces/IDomTypes.interface";
import { DomImage } from "../Image/DomImage.view";
import { DomVideo } from "../Video/Video.view";
import { AppStore } from "../AppStore/AppStore.view";
import { useGlobal } from "../../../../Provider/GlobalContext.provider";
import { IPartialCSS } from "partnerbuilder-common";
import { ContactForm } from "../ContactForm/ContactForm.view";
import { SelectorView } from "../Selector/Selector.view";
import { ColumnStyles } from "../../Static/ColumnStyles.static";
import { QRScannerView } from "../QRScanner/QRScanner.view";
import { InlineLoading } from "../../../UI/InlineLoading/InlineLoading.view";
import { Suspense, lazy } from "react";
import { ParseComponentStyle } from "partnerbuilder-common";
import DomBulletList from "../List/DomBulletList.view";
import DomLinkButton from "../Link/DomLinkButton.view";
import DomHeader from "../Header/DomHeader.view";
import DomLink from "../Link/DomLink.view";
import DomText from "../Text/DomText.view";

export interface IDomColumnProps {
    column: IColumn;
}

const LazyGallery = lazy(() => import("../Gallery/Gallery.view"));

const DomColumn: React.FC<IDomColumnProps> = (props) => {
    const { size, images } = useGlobal();
    const id = props.column.uuid;
    const content = props.column.content as IPartialCSS<any>;

    const classes: string[] = ["column"];
    if (props.column.isSpecial) classes.push("special-column");
    let style = ParseComponentStyle(content, ColumnStyles, size, images);

    return (
        <div className={classes.join(" ")} style={style} id={id}>
            {map(props.column.elements, (element) => {
                switch (element.type) {
                    case "Header":
                        return <DomHeader key={element.uuid} {...element} />;
                    case "Text":
                        return <DomText key={element.uuid} {...element} />;
                    case "Link":
                        return <DomLink key={element.uuid} {...element} />;
                    case "LinkButton":
                        return <DomLinkButton key={element.uuid} {...element} />;
                    case "List":
                        return <DomBulletList key={element.uuid} {...element} />;
                    case "Image":
                        return <DomImage key={element.uuid} {...element} />;
                    case "Video":
                        return <DomVideo key={element.uuid} {...element} />;
                    case "Gallery":
                        return (
                            <Suspense
                                fallback={<InlineLoading />}
                                key={`${element.uuid}-container`}
                            >
                                <LazyGallery key={element.uuid} {...element} />
                            </Suspense>
                        );
                    case "ContactForm":
                        return <ContactForm key={element.uuid} {...element} />;
                    case "AppStore":
                        return <AppStore key={element.uuid} {...element} />;
                    case "Selector":
                        return <SelectorView key={element.uuid} {...element} />;
                    case "QRScanner":
                        return <QRScannerView key={element.uuid} {...element} />;
                    default:
                        return null;
                }
            })}
        </div>
    );
};

export default DomColumn;
