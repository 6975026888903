import { IPartialCSS } from "partnerbuilder-common";
import {
    BackgroundStyles,
    BoxShadowSyles,
    ColorStyles
} from "../../../DomMapper/Static/GroupsStyles.static";

export const FooterStyles: IPartialCSS<any> = {
    ...BackgroundStyles,
    ...BoxShadowSyles,
    ...ColorStyles
};
