import { FC, useRef } from "react";
import { useDidMount } from "xa-generics";
import { createPortal } from "react-dom";
import { GlobalButton } from "../GlobalButton/GlobalButton.view";
import { useTranslation } from "react-i18next";
import { ArrowLeft, Close, Save } from "@carbon/icons-react";
import { useGlobal } from "../../../Provider/GlobalContext.provider";

export interface IModalProps<Lang = string> {
    size?: "XS" | "SM" | "MD" | "LG";
    id?: string;
    closeText?: Lang;
    isDanger?: boolean;
    className?: string;
    submitText?: string;
    isPassive?: boolean;
    allowOverflow?: boolean;
    isSubmitDisabled?: boolean;
    allowEnterKeyDown?: boolean;
    modalCloseIcon?: JSX.Element;
    heading?: string | JSX.Element;
    closeIcon?: FC<any> | JSX.Element;
    submitIcon?: FC<any> | JSX.Element;
    onClose: (e?: React.MouseEvent<HTMLButtonElement | HTMLDivElement, MouseEvent>) => void;
    onSubmit?: (e?: React.MouseEvent<HTMLButtonElement | HTMLDivElement, MouseEvent>) => void;
    onSecondary?: (e: React.MouseEvent<HTMLButtonElement | HTMLDivElement, MouseEvent>) => void;
}

const Modal: React.FC<IModalProps> = (props) => {
    const size = props.size || "XS";
    const { config } = useGlobal();
    const classes: string[] = ["modal", `modal-${size}`];
    const styleRef = useRef<HTMLStyleElement>();

    if (config.is_dark_theme) classes.push("dark-theme");
    if (config.is_rounded_btn) classes.push("rounded-modal");
    const { t } = useTranslation();

    if (props.className) classes.push(props.className);
    if (props.isPassive) classes.push("modal-passive");
    if (props.isDanger) classes.push("modal-danger");
    if (props.allowOverflow) classes.push("modal-allow-overflow");

    const handleKeyBinds = (e: KeyboardEvent): void => {
        if (e.key === "Escape") {
            e.preventDefault();
            props.onClose();
        }
        if (props.allowEnterKeyDown && e.key === "Enter") {
            e.preventDefault();
            if (props.onSubmit) props.onSubmit();
            else props.onClose();
        }
    };

    useDidMount(() => {
        window.addEventListener("keydown", handleKeyBinds);

        const style = document.createElement("style");
        style.innerHTML = "html, body, #root, .page-container { overflow: hidden !important; }";
        styleRef.current = style;
        document.head.appendChild(style);
        return () => {
            window.removeEventListener("keydown", handleKeyBinds);
            document.head.removeChild(styleRef.current!);
            styleRef.current = undefined;
        };
    });

    return (
        <>
            {createPortal(
                <div id={props.id} className="modal-container" onClick={props.onClose}>
                    <div className={classes.join(" ")} onClick={(e) => e.stopPropagation()}>
                        <div className="modal__heading">
                            <div className="modal__heading--content">{props.heading}</div>
                            <div className="modal__heading--close" onClick={props.onClose}>
                                {props.modalCloseIcon ? props.modalCloseIcon : <Close size={24} />}
                            </div>
                        </div>
                        <div className="modal__content">{props.children}</div>
                        {!props.isPassive && (
                            <div className="modal__controls">
                                <GlobalButton
                                    isModal
                                    isSecondary
                                    style={{
                                        borderBottomLeftRadius: config.is_rounded_btn
                                            ? "1rem"
                                            : undefined
                                    }}
                                    RightIcon={props.closeIcon || ArrowLeft}
                                    onClick={props.onSecondary || props.onClose}
                                    className={"modal__controls--button"}
                                >
                                    {t(props.closeText || "cancel")}
                                </GlobalButton>
                                <GlobalButton
                                    isModal
                                    style={{
                                        borderBottomRightRadius: config.is_rounded_btn
                                            ? "1rem"
                                            : undefined
                                    }}
                                    onClick={props.onSubmit}
                                    isDisabled={props.isSubmitDisabled}
                                    RightIcon={props.submitIcon || Save}
                                    className={"modal__controls--button"}
                                >
                                    {props.submitText || t("save")}
                                </GlobalButton>
                            </div>
                        )}
                    </div>
                </div>,
                document.getElementById("root") as HTMLDivElement
            )}
        </>
    );
};

export default Modal;
