import { IError, useAfterTriggerChanged, useMountWithTriggers, useTimeout } from "xa-generics";
import { IFormErrors, IFormInternal } from "../UseForm/IUseForm.interface";
import { Close, Undefined, Search } from "@carbon/icons-react";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { InlineLoading } from "../InlineLoading/InlineLoading.view";
import { FormErrorView } from "../UseForm/FormError.view";
import { FloatingError } from "xa-error-with-lang";
import { useGlobal } from "../../../Provider/GlobalContext.provider";
import { ILang } from "../../../Interfaces/ILang.type";

export interface ISearchInputProps<
    Fields extends object,
    ObjectKind extends object,
    Lang extends string = string
> {
    id: keyof Fields;
    labelText?: Lang;
    noLabel?: boolean;
    textValue: string;
    required?: boolean;
    className?: string;
    altId: keyof Fields;
    placeholder?: string;
    isDisabled?: boolean;
    value: string | number;
    errors?: IFormErrors<Fields>;
    queryAfterCharCount?: number;
    optionIdKey: keyof ObjectKind;
    optionNameKey: keyof ObjectKind;
    onBlur?: React.FocusEventHandler<HTMLInputElement>;
    onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
    focusRef?: React.MutableRefObject<HTMLInputElement | null>;
    queryTextValue: (textValue: string) => Promise<ObjectKind[]>;
    onTextChange: (id: keyof Fields, value: string) => void;
    enterKeyHint?: React.InputHTMLAttributes<HTMLInputElement>["enterKeyHint"];
    onChange: (id: keyof Fields, value: string, option: ObjectKind | null) => void;
}

export const SearchInput = <
    Fields extends object,
    ObjectKind extends object,
    Lang extends string = string
>(
    props: ISearchInputProps<Fields, ObjectKind, Lang>
) => {
    let wrapperStyle: string[] = ["wrapper", "search-input"];
    const { t } = useTranslation();
    const { config } = useGlobal();
    const { setTm } = useTimeout();

    const id = props.id as string;
    const lockEvent = useRef<boolean>(false);
    const uniqueKey = `${id}-option-container`;

    const [selectedOption, setSelectedOption] = useState<ObjectKind | null>(null);
    const [optionList, setOptionList] = useState<ObjectKind[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isEmpty, setIsEmpty] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [error, setError] = useState<IError>(null);

    const handleClick = (e: MouseEvent): void => {
        const path = e.composedPath();
        for (let target of path) {
            const id: string | undefined = (target as HTMLElement)?.id;
            if (id === uniqueKey) return;
        }
        setIsOpen(false);
    };
    useAfterTriggerChanged(() => {
        setIsOpen(false);
    }, [selectedOption]);

    if (props.className) wrapperStyle.push(props.className);
    if (props.isDisabled) wrapperStyle.push("wrapper-disabled");
    if (config.is_rounded_btn) wrapperStyle.push("global__rounded-corners");

    useAfterTriggerChanged(() => {
        if (lockEvent.current) {
            lockEvent.current = false;
            return;
        }

        const queryAfterCharCount = props.queryAfterCharCount || 1;
        if (props.textValue.length > queryAfterCharCount) {
            setIsLoading(true);
            setTm(
                () => {
                    props
                        .queryTextValue(props.textValue)
                        .then((objectsOfKind) => {
                            setOptionList(objectsOfKind);
                            if (objectsOfKind.length > 0) {
                                setIsOpen(true);
                                setIsEmpty(false);
                            } else setIsEmpty(true);
                        })
                        .catch((requestError) => setError(requestError))
                        .finally(() => setIsLoading(false));
                },
                500,
                "quickpress-timeout"
            );
        } else {
            setSelectedOption(null);
            setOptionList([]);
            setIsEmpty(false);
        }
    }, [props.textValue, props.queryAfterCharCount]);

    useMountWithTriggers(() => {
        window.addEventListener("mouseup", handleClick, { passive: true });
        return () => window.removeEventListener("mouseup", handleClick);
    }, [isOpen, selectedOption]);

    return (
        <>
            <div className={wrapperStyle.join(" ")}>
                {!props.noLabel && (
                    <label htmlFor={id} className="input-label">
                        {t(props.labelText || id)}
                        {props.required ? "*" : ""}
                    </label>
                )}
                <input
                    id={id}
                    name={id}
                    type={"text"}
                    autoSave={"off"}
                    autoComplete={"off"}
                    enterKeyHint={"next"}
                    onBlur={props.onBlur}
                    value={props.textValue}
                    disabled={props.isDisabled}
                    onKeyDown={props.onKeyDown}
                    placeholder={props.placeholder}
                    onClick={() => {
                        if (optionList.length > 0) {
                            setIsOpen(true);
                        }
                    }}
                    className={"common-input text-input search-pw-input"}
                    ref={(ref) => {
                        if (props.focusRef) props.focusRef.current = ref;
                    }}
                    onChange={(e) => {
                        const value = e.target.value;
                        if (value.length < props.textValue.length && value.length !== 0) {
                            lockEvent.current = true;
                        }
                        props.onTextChange(props.altId, value);
                    }}
                />

                <div
                    onClick={() => {
                        setIsEmpty(false);
                        setSelectedOption(null);
                        props.onTextChange(props.altId, "");
                    }}
                    className={"text-input-unit clickable-unit"}
                >
                    {isLoading ? (
                        <InlineLoading />
                    ) : isEmpty ? (
                        <Undefined title={t<ILang>("no_data")} style={{ color: "red" }} />
                    ) : props.textValue || selectedOption ? (
                        <Close />
                    ) : (
                        <Search />
                    )}
                </div>

                {props.errors && (
                    <FormErrorView
                        id={props.id}
                        errors={
                            isEmpty
                                ? ({
                                      [props.id]: {
                                          pattern: t<ILang>("no_results")
                                      }
                                  } as Partial<IFormInternal<Fields, any>>)
                                : props.errors
                        }
                    />
                )}

                {isOpen && optionList.length > 0 ? (
                    <div
                        id={uniqueKey}
                        className={"search-option-list"}
                        style={{ borderColor: config.main_btn_bg_color }}
                    >
                        <div className="search-option-list__header">
                            {t<ILang>("choose_from_list")}
                        </div>
                        <div className="search-option-list__scroll">
                            {optionList.map((option) => {
                                const isActive =
                                    selectedOption &&
                                    option[props.optionIdKey] === selectedOption[props.optionIdKey];
                                const style: React.CSSProperties = {};
                                if (isActive) {
                                    style.backgroundColor = config.main_btn_bg_color;
                                    style.color = config.main_btn_color;
                                }
                                return (
                                    <button
                                        type="button"
                                        style={style}
                                        className={"search-option"}
                                        key={`${option[props.optionIdKey]}-${id}-option`}
                                        onClick={() => {
                                            lockEvent.current = true;
                                            setSelectedOption(option);
                                            props.onChange(
                                                props.id,
                                                option[props.optionIdKey as never],
                                                option
                                            );
                                        }}
                                    >
                                        {option[props.optionNameKey]}
                                    </button>
                                );
                            })}
                        </div>
                    </div>
                ) : null}
            </div>
            <FloatingError error={error} resetError={() => setError(null)} />
        </>
    );
};
