import { IPartialCSS } from "partnerbuilder-common";

export const PaddingStyles: IPartialCSS<true> = {
    paddingTop: true,
    paddingLeft: true,
    paddingRight: true,
    paddingBottom: true
};

export const MarginStyles: IPartialCSS<true> = {
    marginTop: true,
    marginLeft: true,
    marginRight: true,
    marginBottom: true
};

export const FontStyles: IPartialCSS<true> = {
    textDecoration: true,
    letterSpacing: true,
    fontStyle: true,
    lineHeight: true,
    fontFamily: true,
    fontWeight: true,
    fontSize: true,
    textTransform: true
};

export const BackgroundStyles: IPartialCSS<true> = {
    background: true,
    backgroundColor: true
};

export const BackgroundImageStyles: IPartialCSS<true> = {
    backgroundSize: true,
    backgroundImage: true,
    backgroundRepeat: true,
    backgroundPosition: true,
    backgroundAttachment: true
};

export const BorderRadiusStyles: IPartialCSS<true> = {
    borderTopLeftRadius: true,
    borderTopRightRadius: true,
    borderBottomRightRadius: true,
    borderBottomLeftRadius: true
};

export const BorderStyles: IPartialCSS<true> = {
    borderColor: true,
    borderStyle: true,
    borderTopWidth: true,
    borderLeftWidth: true,
    borderBottomWidth: true,
    borderRightWidth: true
};

export const OpacityStyles: IPartialCSS<true> = {
    opacity: true
};

export const BoxShadowSyles: IPartialCSS<true> = {
    boxShadow: true
};

export const TextShadowSyles: IPartialCSS<true> = {
    textShadow: true
};

export const HeightStyles: IPartialCSS<true> = {
    minHeight: true,
    height: true,
    maxHeight: true
};

export const WidthStyles: IPartialCSS<true> = {
    minWidth: true,
    width: true,
    maxWidth: true
};

export const AlignStyles: IPartialCSS<true> = {
    textAlign: true
};

export const ColorStyles: IPartialCSS<true> = {
    color: true
};

export const DisplayStyles: IPartialCSS<true> = {
    display: true,
    justifyContent: true,
    alignItems: true,
    alignSelf: true,
    flexDirection: true,
    flexWrap: true
};
