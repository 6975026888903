import { IPartialCSS } from "partnerbuilder-common";
import {
    BackgroundStyles,
    BoxShadowSyles,
    PaddingStyles,
    ColorStyles,
    FontStyles
} from "../../../DomMapper/Static/GroupsStyles.static";

export const NavbarStyles: IPartialCSS<any> = {
    ...BackgroundStyles,
    ...BoxShadowSyles,
    ...PaddingStyles,
    ...ColorStyles,
    ...FontStyles
};
