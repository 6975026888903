import { IPartialCSS } from "partnerbuilder-common";
import {
    FontStyles,
    ColorStyles,
    PaddingStyles
} from "../../../DomMapper/Static/GroupsStyles.static";

export const NavbarNameStyles: IPartialCSS<any> = {
    ...PaddingStyles,
    ...ColorStyles,
    ...FontStyles
};
