import { useTranslation } from "react-i18next";
import { FormErrorView } from "../UseForm/FormError.view";
import { IFormErrors } from "../UseForm/IUseForm.interface";
import { useGlobal } from "../../../Provider/GlobalContext.provider";

export interface ITextAreaProps<Fields extends object, Lang extends string = string> {
    value?: string;
    id: keyof Fields;
    labelText?: Lang;
    noLabel?: boolean;
    className?: string;
    required?: boolean;
    isHidden?: boolean;
    placeholder?: Lang;
    isDisabled?: boolean;
    errors?: IFormErrors<Fields>;
    description?: string | JSX.Element;
    onChange: (id: keyof Fields, value: string) => void;
}

export const TextArea = <Fields extends object, Lang extends string = string>(
    props: ITextAreaProps<Fields, Lang>
) => {
    let wrapperStyle: string[] = ["wrapper"];
    const { config, currentFont } = useGlobal();
    const { t } = useTranslation();

    if (props.className) wrapperStyle.push(props.className);
    if (props.isHidden) wrapperStyle.push("wrapper-hidden");
    if (props.isDisabled) wrapperStyle.push("wrapper-disabled");
    if (config.is_rounded_btn) wrapperStyle.push("global__rounded-corners");
    const id = props.id as string;

    return (
        <div className={wrapperStyle.join(" ")}>
            {!props.noLabel && (
                <label htmlFor={id} className="input-label">
                    {t(props.labelText || id)}
                    {props.required ? "*" : ""}
                </label>
            )}
            <textarea
                id={id}
                name={id}
                value={props.value}
                required={props.required}
                disabled={props.isDisabled}
                className={`common-input text-area ${currentFont}`}
                placeholder={t<Lang>(props.placeholder as Lang)}
                onChange={(e) => props.onChange(props.id, e.target.value)}
            />
            {props.errors && <FormErrorView id={props.id} errors={props.errors} />}
            {props.description ? (
                <div className="input-description">{props.description}</div>
            ) : null}
        </div>
    );
};
