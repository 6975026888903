import { ILang } from "../../../../Interfaces/ILang.type";
import { useMemo } from "react";
import { useGlobal } from "../../../../Provider/GlobalContext.provider";
import { IDomElement } from "../../../../Interfaces/IDomTypes.interface";
import { useTranslation } from "react-i18next";
import { TextTypeStyles } from "../../Static/TextTypeStyles.static";
import { IHeaderTypeContent } from "../../Interfaces/IHeaderEditor.interface";
import { ParseComponentStyle, TextStyleSeparator } from "partnerbuilder-common";

export interface IDomHeaderProps extends IDomElement<IHeaderTypeContent> {}

const DomHeader: React.FC<IDomHeaderProps> = (props) => {
    const { i18n } = useTranslation<ILang>();
    const currentLang = i18n.language;
    const { size } = useGlobal();

    const content = props.content as IHeaderTypeContent;

    let style = useMemo(() => ParseComponentStyle(content, TextTypeStyles, size), [content, size]);
    const classes: string[] = ["h", `dom__header--h${content.headerType || 1}`];

    const { textStyles, divStyles } = useMemo(() => TextStyleSeparator(style || {}), [style]);

    const Heading = (): JSX.Element => {
        const text = content?.[currentLang]?.text || "";
        switch (content.headerType) {
            case 6:
                return (
                    <h6 style={textStyles} className={classes.join(" ")}>
                        {text}
                    </h6>
                );
            case 5:
                return (
                    <h5 style={textStyles} className={classes.join(" ")}>
                        {text}
                    </h5>
                );
            case 4:
                return (
                    <h4 style={textStyles} className={classes.join(" ")}>
                        {text}
                    </h4>
                );
            case 3:
                return (
                    <h3 style={textStyles} className={classes.join(" ")}>
                        {text}
                    </h3>
                );
            case 2:
                return (
                    <h2 style={textStyles} className={classes.join(" ")}>
                        {text}
                    </h2>
                );
            case 1:
            default:
                return (
                    <h1 style={textStyles} className={classes.join(" ")}>
                        {text}
                    </h1>
                );
        }
    };

    return (
        <div className="dom__header" id={props.uuid} style={divStyles}>
            {Heading()}
        </div>
    );
};

export default DomHeader;
