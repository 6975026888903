import { IPartialCSS } from "partnerbuilder-common";
import {
    BackgroundStyles,
    HeightStyles,
    MarginStyles,
    PaddingStyles,
    WidthStyles
} from "./GroupsStyles.static";

export const VideoStyles: IPartialCSS<any> = {
    ...WidthStyles,
    ...HeightStyles,
    ...MarginStyles,
    ...PaddingStyles,
    ...BackgroundStyles
};
