import { IPartialCSS, ParseComponentStyle } from "partnerbuilder-common";
import { IVideoContent } from "../../Interfaces/IVideoComponent.interface";
import { HeightStyles } from "../../Static/GroupsStyles.static";
import { VideoStyles } from "../../Static/VideoStyles.static";
import { IDomElement } from "../../../../Interfaces/IDomTypes.interface";
import { useGlobal } from "../../../../Provider/GlobalContext.provider";
import { FC } from "react";

interface IDomVideoProps extends IDomElement<IVideoContent> {}

export const DomVideo: FC<IDomVideoProps> = (props) => {
    const { size } = useGlobal();
    let style = ParseComponentStyle(props.content, VideoStyles, size);

    const iframeStyle: IPartialCSS<any> = {};
    for (let cssKey in style) {
        if (cssKey in HeightStyles) {
            iframeStyle[cssKey as never] = style[cssKey as never];
            delete style[cssKey as never];
        }
    }

    return (
        <div style={style} id={props.uuid} className="dom__video">
            {props.content.youtube_url && (
                <iframe
                    src={props.content.youtube_url}
                    title="YouTube video player"
                    className={"dom__video--player"}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    style={iframeStyle}
                    allowFullScreen
                />
            )}
        </div>
    );
};
