import { IPartialCSS } from "partnerbuilder-common";
import {
    BackgroundImageStyles,
    BackgroundStyles,
    DisplayStyles,
    HeightStyles,
    PaddingStyles,
    WidthStyles
} from "./GroupsStyles.static";

export const ColumnStyles: IPartialCSS<true> = {
    ...WidthStyles,
    ...HeightStyles,
    ...DisplayStyles,
    ...PaddingStyles,
    ...BackgroundStyles,
    ...BackgroundImageStyles
};
