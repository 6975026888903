import { ILinkContent, ILinkCustoms } from "../../Interfaces/ILinkComponent.interface";
import { ParseComponentStyle } from "partnerbuilder-common";
import { useTranslation } from "react-i18next";
import { IDomElement } from "../../../../Interfaces/IDomTypes.interface";
import { LinkStyles } from "../../Static/LinkStyles.static";
import { useGlobal } from "../../../../Provider/GlobalContext.provider";
import { NavLink } from "react-router-dom";
import { ILang } from "../../../../Interfaces/ILang.type";

export interface IDomLinkProps extends IDomElement<ILinkContent> {}

const DomLink: React.FC<IDomLinkProps> = (props) => {
    const { i18n } = useTranslation<ILang>();
    const currentLang = i18n.language;
    const { config, size } = useGlobal();

    const content = props.content as ILinkContent;
    let style = ParseComponentStyle(content, LinkStyles, size);
    const data = content?.[currentLang] as ILinkCustoms | undefined;
    const text = (data?.text as string) || "";

    const getLink = (text: string): JSX.Element => {
        let url = data?.url || "#";
        if (data?.isExternal && data?.openOnNewTab) {
            return (
                <a rel={"noreferrer noopener"} target={"_blank"} href={url}>
                    {text}
                </a>
            );
        } else {
            if (!data?.openOnNewTab && url.indexOf(config.domain) > -1) {
                url = url.split(config.domain)[1];
            }
            return <NavLink to={url}>{text}</NavLink>;
        }
    };

    return (
        <div className="dom__link" style={style} id={props.uuid}>
            {getLink(text)}
        </div>
    );
};

export default DomLink;
