import { FormatPhoneNumber, ParseComponentStyle, RestaurantModel } from "partnerbuilder-common";
import { BackgroundImageStyles } from "../../Static/GroupsStyles.static";
import { FC, useMemo, useState } from "react";
import { Launch, Restaurant } from "@carbon/icons-react";
import { ISelectorContent } from "../../Interfaces/ISelectorComponent.interface";
import { ISelectorLayouts } from "./Interfaces/ISelectorLayouts.type";
import { SelectorStyles } from "../../Static/SelectorStyles.static";
import { useTranslation } from "react-i18next";
import { RawSelectInput } from "../../../UI/Inputs/RawSelectInput.view";
import { SelectorForm } from "./SelectorForm.view";
import { IDomElement } from "../../../../Interfaces/IDomTypes.interface";
import { useGlobal } from "../../../../Provider/GlobalContext.provider";
import { cloneDeep } from "lodash";
import { ILang } from "../../../../Interfaces/ILang.type";

interface ISelectorViewProps extends IDomElement<ISelectorContent> {}

export const SelectorView: FC<ISelectorViewProps> = (props) => {
    const { t } = useTranslation<ILang>();
    const { restaurants, images, size, PLATFORM, CLIENT_URL, config, startPages } = useGlobal();

    const selectableRestaurants = useMemo(() => {
        const arr = [];
        for (const rest of restaurants) {
            if (startPages.find((startPage) => startPage.restaurant_id === rest.id)) {
                arr.push(rest);
            }
        }
        return arr;
    }, [restaurants, startPages]);

    const genUrl = (rest: RestaurantModel): string => {
        let url = `https://${rest.domain}`;
        if (PLATFORM !== "browser" || !config.domain) {
            url = CLIENT_URL + `/apps/${PLATFORM}/${rest.id}?lastSelectedRestId=${rest.id}`;
        }
        return url;
    };

    const [restId, setRestId] = useState<string>("");

    const rest = useMemo(() => {
        return selectableRestaurants.find((rest) => rest.id === restId);
    }, [restId, selectableRestaurants]);

    const style = useMemo(() => {
        const s = ParseComponentStyle(
            props.content,
            SelectorStyles,
            size,
            images
        ) as React.CSSProperties;
        return cloneDeep(s);
    }, [props.content, size, images]);

    const layoutType = props.content.layoutType as ISelectorLayouts;
    const topLogo = props.content.topLogo;
    const logoTopSpacing = props.content.logoTopSpacing || 10;
    const classes = ["selector", `selector-${size}`, `selector-${layoutType}`];
    const restCount = selectableRestaurants.length;

    if (layoutType === "tile_layout") {
        if (restCount % 3 === 0) classes.push("--3col");
        else if (restCount % 2 === 0) classes.push("--2col");
        else classes.push("--1col");
        classes.push(`--${props.content.tileAlign || "CENTER"}`);
    }

    let url = "";
    if (props.content.noBackground) {
        style.backgroundImage = "none";
    }
    if (rest) url = genUrl(rest);

    let defaultLogoUrl = "";
    if (topLogo && images[topLogo]) {
        defaultLogoUrl = images[topLogo].url;
    }
    let addressContainerStyle = {};
    if (props.content.isCenteredWithSideImage) {
        for (const key in BackgroundImageStyles) {
            if (style[key as never]) {
                addressContainerStyle[key as never] = style[key as never];
            }
        }
        classes.push("--CENTERED_STYLE_WITH_SIDE_IMAGE");
    }

    return (
        <div
            style={
                props.content.isCenteredWithSideImage || props.content.noBackground
                    ? { ...style, backgroundImage: "none" }
                    : style
            }
            id={props.uuid}
            className={classes.join(" ")}
        >
            {layoutType === "default_layout" && (
                <div className={"selector__input-container"}>
                    {defaultLogoUrl && (
                        <div
                            className={"selector__image-container"}
                            style={{ top: `-${logoTopSpacing}rem` }}
                        >
                            <img
                                className={"selector__image-container--top-logo"}
                                src={defaultLogoUrl}
                                alt="Logo"
                            />
                        </div>
                    )}
                    <h1 className="selector__title">{t<ILang>("restaurant_selector")}</h1>
                    <div className="selector__description">
                        {t<ILang>("select_closer_restaurant")}
                    </div>
                    <RawSelectInput
                        noLabel
                        value={restId}
                        id={"restaurant-selector"}
                        options={selectableRestaurants}
                        onChange={(id, value) => setRestId(value)}
                        description={
                            rest ? (
                                <div className={"selector__select-submit"}>
                                    <a
                                        style={{
                                            backgroundColor: config.main_btn_bg_color,
                                            color: config.main_btn_color
                                        }}
                                        className={"atag-link"}
                                        href={url}
                                    >
                                        {t<ILang>("next")} <Launch className={"icon"} />
                                    </a>
                                </div>
                            ) : undefined
                        }
                    />
                </div>
            )}
            {layoutType === "tile_layout" && (
                <>
                    {selectableRestaurants.map((rest, index) => {
                        const url = genUrl(rest);
                        const cls = ["selector__tile"];
                        if (restCount % 2 === 0) {
                            if ((index + 1) % 2 === 0) cls.push("--align-left");
                            else if (restCount < 1) cls.push("--align-right");
                        }
                        return (
                            <a
                                href={url}
                                target={"_self"}
                                className={cls.join(" ")}
                                key={`${rest.id}-partner-tile`}
                            >
                                <div className="selector__tile--icon">
                                    <Restaurant size={50} />
                                </div>
                                <div className="selector__tile--location">
                                    {rest.city}, {rest.street} {rest.premise_number}
                                </div>
                                <div className="selector__tile--name">{rest.name}</div>
                                <div className="selector__tile--phone">
                                    {FormatPhoneNumber(rest.phone_1)}
                                </div>
                                <div className="selector__tile--email">{rest.contact_email}</div>
                            </a>
                        );
                    })}
                </>
            )}
            {layoutType === "address_based" && (
                <div className="address-container" style={addressContainerStyle}>
                    <SelectorForm selectableRestaurants={selectableRestaurants} />
                </div>
            )}
        </div>
    );
};
