import { ModelConstructor, IDynamicObject } from "xa-generics";

export class StreetModel {
    constructor(rawData?: IDynamicObject<any>) {
        if (!rawData) return;
        const data = rawData as StreetMData;
        ModelConstructor(data, this);

        this.combinedName = this.name + ` (+${this.price || 0} Ft)`;
    }

    id: string = "";
    name: string = "";
    delivery_region_id: string = "";
    archived_at: string = "";
    postcode: string = "";
    price: string = "";
    active: boolean = false;
    created_at: string = "";
    updated_at: string = "";

    //custom prop
    combinedName: string = "";
}

//If you add public methods, you may want to replace the empty string with them!
type StreetMData = Partial<InstanceType<typeof StreetModel>>;
