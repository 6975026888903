import { Email } from "@carbon/icons-react";
import { ILang } from "../../../../Interfaces/ILang.type";
import { useGlobal } from "../../../../Provider/GlobalContext.provider";
import { FooterStyles } from "../Static/FooterStyles.static";
import { useTranslation } from "react-i18next";
import { FooterNameStyles } from "../Static/FooterNameStyles.static";
import { ParseComponentStyle } from "partnerbuilder-common";
import LogoRed from "../../../../Assets/logo_red.png";

export interface IWidgetFooterProps {
    footRef: React.MutableRefObject<HTMLElement | null>;
}

const WidgetFooter: React.FC<IWidgetFooterProps> = (props) => {
    const { size, images, widgets, partner, currentFont, config } = useGlobal();
    const { t } = useTranslation<ILang>();

    const { footerName, footerLogo, footerStyle, isNameHidden } = widgets.Footer.settings;
    const nameStyle = ParseComponentStyle(footerName, FooterNameStyles, size);
    const style = ParseComponentStyle(footerStyle, FooterStyles, size);
    const classes: string[] = ["widget footer", `footer-${size}`, currentFont];

    if (config.is_dark_theme) classes.push("dark-footer");

    return (
        <footer
            style={style}
            className={classes.join(" ")}
            ref={(ref) => (props.footRef.current = ref)}
        >
            <div className="footer__logo">
                {isNameHidden === false && (
                    <div className={"footer__logo--name"} style={nameStyle}>
                        {partner.name}
                    </div>
                )}
                {footerLogo && images[footerLogo]?.url && (
                    <img
                        alt={partner.name}
                        src={images[footerLogo].url}
                        className={"footer__logo--image"}
                    />
                )}
            </div>
            <div className="footer__content">
                <address className="footer__content--block">
                    <div className="footer__content--block--icon">
                        <Email size={24} />
                    </div>
                    <div className="footer__content--block--data">
                        <a
                            href={`mailto: ${partner.contact_email || "example@email.com"}`}
                            className="email"
                            target={"_blank"}
                            rel={"noreferrer noopener"}
                        >
                            {partner.contact_email || "example@email.com"}
                        </a>
                    </div>
                </address>
                <a
                    target={"_blank"}
                    rel={"noreferrer noopener"}
                    href={"https://oneminorder.com"}
                    className={"footer__content--logo"}
                >
                    {t<ILang>("powered_by")}{" "}
                    <img src={LogoRed} alt="Onemin" className={"footer__content--logo--onemin"} />
                </a>
                <div className="footer__content--policy-files">
                    <a
                        className={"global__link policy-link"}
                        href={"/unknown"}
                        rel={"noreferrer noopener"}
                        target={"_blank"}
                        onClick={(e) => e.preventDefault()}
                    >
                        {t<ILang>("footer_privacy_policy")}
                    </a>
                    <a
                        className={"global__link policy-link"}
                        href={"/unknown"}
                        rel={"noreferrer noopener"}
                        target={"_blank"}
                        onClick={(e) => e.preventDefault()}
                    >
                        {t<ILang>("footer_terms_and_conditions")}
                    </a>
                </div>
            </div>
        </footer>
    );
};

export default WidgetFooter;
