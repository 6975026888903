import { CodeHide, View } from "@carbon/icons-react";
import { useTranslation } from "react-i18next";
import { FormErrorView } from "../UseForm/FormError.view";
import { IFormErrors } from "../UseForm/IUseForm.interface";
import { useGlobal } from "../../../Provider/GlobalContext.provider";
import { useState } from "react";
import { Regexes } from "xa-generics";

export interface ITextInputProps<Fields extends object, Lang extends string = string> {
    min?: number;
    max?: number;
    prefix?: string;
    id: keyof Fields;
    labelText?: Lang;
    noLabel?: boolean;
    required?: boolean;
    numRegExp?: RegExp;
    className?: string;
    isNumeric?: boolean;
    placeholder?: string;
    isDisabled?: boolean;
    value: string | number;
    autoComplete?: boolean;
    unit?: string | JSX.Element;
    errors?: IFormErrors<Fields>;
    description?: string | JSX.Element;
    onBlur?: React.FocusEventHandler<HTMLInputElement>;
    onChange: (id: keyof Fields, value: string) => void;
    onUnitClick?: (clickEvent: React.MouseEvent) => void;
    onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
    focusRef?: React.MutableRefObject<HTMLInputElement | null>;
    enterKeyHint?: React.InputHTMLAttributes<HTMLInputElement>["enterKeyHint"];
    type?: "text" | "number" | "email" | "password" | "url" | "tel" | "search";
}

export const TextInput = <Fields extends object, Lang extends string = string>(
    props: ITextInputProps<Fields, Lang>
) => {
    let wrapperStyle: string[] = ["wrapper"];
    const { t } = useTranslation();
    const { config } = useGlobal();
    const [showPw, setShowPw] = useState<boolean>(false);
    if (props.className) wrapperStyle.push(props.className);
    if (props.isDisabled) wrapperStyle.push("wrapper-disabled");
    if (config.is_rounded_btn) wrapperStyle.push("global__rounded-corners");

    const id = props.id as string;

    const getUnit = (): null | JSX.Element => {
        if (!props.unit) {
            if (props.type !== "password") return null;

            return (
                <div
                    onMouseUp={() => setShowPw(!showPw)}
                    className={"text-input-unit clickable-unit"}
                >
                    {showPw ? <CodeHide /> : <View />}
                </div>
            );
        }

        const classes: string[] = ["text-input-unit"];
        if (props.noLabel) classes.push("text-input-unit-without-label");
        if (props.onUnitClick) classes.push("clickable-unit");

        return (
            <div
                onMouseUp={(e: any) => {
                    const clickEvent = e as React.MouseEvent;
                    if (props.onUnitClick) props.onUnitClick(clickEvent);
                }}
                className={classes.join(" ")}
            >
                {props.unit}
            </div>
        );
    };

    return (
        <div className={wrapperStyle.join(" ")}>
            {!props.noLabel && (
                <label htmlFor={id} className="input-label">
                    {t(props.labelText || id)}
                    {props.required ? "*" : ""}
                </label>
            )}
            <input
                id={id}
                name={id}
                min={props.min}
                max={props.max}
                enterKeyHint={"done"}
                value={props.value}
                onBlur={props.onBlur}
                prefix={props.prefix}
                disabled={props.isDisabled}
                onKeyDown={props.onKeyDown}
                className={"common-input text-input"}
                type={showPw ? "text" : props.type || "text"}
                placeholder={props.placeholder}
                ref={(ref) => {
                    if (props.focusRef) props.focusRef.current = ref;
                }}
                onChange={(e) => {
                    const value = e.target.value;
                    const regexToUse = props.numRegExp || Regexes.Numeric;
                    if (props.isNumeric && regexToUse.test(value) === false && value !== "") return;
                    else props.onChange(props.id, value);
                }}
                autoComplete={props.autoComplete ? "on" : "off"}
            />

            {getUnit()}

            {props.errors && <FormErrorView id={props.id} errors={props.errors} />}
            {props.description ? (
                <div className="input-description">{props.description}</div>
            ) : null}
        </div>
    );
};
