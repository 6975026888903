import { ModelConstructor, IDynamicObject } from "xa-generics";
import { IPageData, SeoModel } from "partnerbuilder-common";
import { PageModel } from "./Page.model";
import { DomModel } from "./Dom.model";

export class PageDataModel extends PageModel implements IPageData<DomModel, string, SeoModel> {
    constructor(rawData: IDynamicObject<any>, platform?: string, partner_id?: string) {
        super(rawData?.seos, platform, partner_id);
        if (!rawData) return;
        const data = rawData as PageDataMData;
        ModelConstructor(data, this);

        if (data.elements) this.elements = new DomModel(data.elements);
    }

    elements: DomModel = new DomModel();
}

//If you add public methods, you may want to replace the empty string with them!
export type PageDataMData = Partial<InstanceType<typeof PageDataModel>>;
