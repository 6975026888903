import { FC } from "react";
import { useGlobal } from "../../../../Provider/GlobalContext.provider";
import { NavbarNameStyles } from "../Static/NavbarName.static";
import { ParseComponentStyle } from "partnerbuilder-common";

interface INavbarNameAndLogoProps {}

export const NavbarNameAndLogo: FC<INavbarNameAndLogoProps> = (props) => {
    const { partner, widgets, images, size } = useGlobal();
    const { navbarName, navbarLogo, isNameHidden } = widgets.Navbar.settings;

    const nameStyles = ParseComponentStyle(navbarName, NavbarNameStyles, size);
    return (
        <div className="navbar__logo">
            {navbarLogo && images[navbarLogo]?.url && (
                <div className="navbar__logo--image-container">
                    <img
                        src={images[navbarLogo].url}
                        alt={partner.name}
                        className={"navbar__logo--image-container--image"}
                    />
                </div>
            )}

            {!isNameHidden && (
                <h1 className="navbar__logo--name" style={nameStyles}>
                    {partner.name}
                </h1>
            )}
        </div>
    );
};
