import { IPartialCSS } from "partnerbuilder-common";
import {
    AlignStyles,
    BackgroundStyles,
    BorderRadiusStyles,
    BorderStyles,
    BoxShadowSyles,
    ColorStyles,
    FontStyles,
    HeightStyles,
    MarginStyles,
    OpacityStyles,
    PaddingStyles,
    TextShadowSyles,
    WidthStyles
} from "./GroupsStyles.static";

export const BulletListTypeStyles: IPartialCSS<true> = {
    ...FontStyles,
    ...BorderStyles,
    ...BackgroundStyles,
    ...MarginStyles,
    ...PaddingStyles,
    ...BorderRadiusStyles,
    ...OpacityStyles,
    ...BoxShadowSyles,
    ...TextShadowSyles,
    ...WidthStyles,
    ...HeightStyles,
    ...ColorStyles,
    ...AlignStyles
};
