import { merge } from "lodash";
import { useGlobal } from "../../../Provider/GlobalContext.provider";
import { IPartialCSS } from "partnerbuilder-common";
import { FC, isValidElement } from "react";

export interface IGlobalButtonProps {
    isModal?: boolean;
    className?: string;
    isDisabled?: boolean;
    isSecondary?: boolean;
    type?: "button" | "reset" | "submit";
    LeftIcon?: FC<any> | React.ReactNode;
    RightIcon?: FC<any> | React.ReactNode;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    onMouseUp?: React.MouseEventHandler<HTMLButtonElement>;
    /*** APP specific button prop (if dataSource is 'app')*/
    isThick?: boolean;
    /*** SITEBUILDER specific button prop (if dataSource is 'config')*/
    style?: IPartialCSS<any>;
    /*** SITEBUILDER specific button prop (if dataSource is 'config')*/
    isTextType?: boolean;
}

export const GlobalButton: FC<IGlobalButtonProps> = (props) => {
    const { config, currentFont } = useGlobal();
    const classes = ["dom__button", currentFont];
    const style: React.CSSProperties = {};
    if (props.LeftIcon && props.RightIcon) classes.push("with-two-icons");
    else {
        if (props.LeftIcon) classes.push("with-left-icon");
        if (props.RightIcon) classes.push("with-right-icon");
    }
    if (props.isTextType) {
        classes.push("--TEXT_BUTTON");
        style.color = config.main_btn_bg_color;
    } else if (props.isSecondary) {
        classes.push("--SECONDARY_BUTTON");
        style.backgroundColor = config.secondary_btn_bg_color;
        style.color = config.secondary_btn_color;
    } else {
        style.backgroundColor = config.main_btn_bg_color;
        style.color = config.main_btn_color;
    }
    if (config.is_rounded_btn && !props.isModal) {
        classes.push("global__rounded-corners");
    }
    if (props.className) classes.push(props.className);

    return (
        <button
            style={merge(style, props.style)}
            type={props.type || "button"}
            className={classes.join(" ")}
            disabled={props.isDisabled}
            onMouseUp={props.onMouseUp}
            onClick={props.onClick}
        >
            {props.LeftIcon && (
                <div className="dom__button--icons dom__button--left-icon">
                    {/* @ts-ignore */}
                    {isValidElement(props.LeftIcon) ? props.LeftIcon : <props.LeftIcon />}
                </div>
            )}
            <div className="dom__button--text">{props.children}</div>
            {props.RightIcon && (
                <div className="dom__button--icons dom__button--right-icon">
                    {isValidElement(props.RightIcon) ? (
                        props.RightIcon
                    ) : (
                        /* @ts-ignore */
                        <props.RightIcon />
                    )}
                </div>
            )}
        </button>
    );
};
