import { orderBy } from "lodash";
import { IContact } from "../Interfaces/IContact.interface";
import { AXIOS_NAMES } from "../Static/AxiosNames.static";
import { WidgetsModel } from "../Models/Widgets.model";
import { PageDataModel } from "../Models/PageData.model";
import { StartPageModel } from "../Models/StartPage.model";
import { axios, IDynamicObject } from "xa-generics";
import { ConfigModel, ImageModel, PartnerModel, RestaurantModel } from "partnerbuilder-common";
import { RegionModel } from "../Models/Region.model";
import { StreetModel } from "../Models/Street.model";

export abstract class GlobalDAO {
    protected static pid: string = process.env.REACT_APP_PID!;
    private static header = {
        headers: {
            "client-backend-secret": process.env.REACT_APP_STAGING_SECRET!
        }
    };

    public static setPID(pid: string): void {
        this.pid = pid;
    }

    protected static url(path?: string, includeV3: boolean = false): string {
        let route = `/api`;
        if (includeV3) route += "/v3";
        route += `/partners/${this.pid}`;
        if (path) route += path;
        return route;
    }

    public static async loadWidget(): Promise<WidgetsModel> {
        const request = await axios
            .getInstance()
            .get<IDynamicObject<any>>(this.url("/widgets"), this.header);
        return new WidgetsModel(request.data);
    }

    public static async loadPages(): Promise<PageDataModel[]> {
        const request = await axios
            .getInstance()
            .get<IDynamicObject<any>[]>(this.url("/pages/public"), this.header);
        return request.data.map((item) => new PageDataModel(item));
    }

    public static async loadConfig(): Promise<ConfigModel> {
        const request = await axios
            .getInstance()
            .get<IDynamicObject<any>>(this.url("/configs"), this.header);
        return new ConfigModel(request.data);
    }

    public static async loadImages(): Promise<ImageModel[]> {
        const request = await axios
            .getInstance()
            .get<IDynamicObject<any>[]>(this.url("/images"), this.header);
        return request.data.map((item) => new ImageModel(item));
    }

    public static async loadPartner(): Promise<PartnerModel> {
        const request = await axios
            .getInstance(AXIOS_NAMES.ONEMIN)
            .get<IDynamicObject<any>[]>(this.url("", true));
        return new PartnerModel(request.data);
    }

    public static async loadRestaurants(): Promise<RestaurantModel[]> {
        const request = await axios
            .getInstance(AXIOS_NAMES.ONEMIN)
            .get<IDynamicObject<any>[]>(this.url("/restaurants", true));
        return orderBy(
            request.data.map((item) => new RestaurantModel(item)),
            "name",
            "asc"
        );
    }

    public static async loadStarPages(): Promise<StartPageModel[]> {
        const request = await axios
            .getInstance()
            .get<IDynamicObject<any>[]>(this.url("/start-pages"), this.header);
        return request.data.map((item) => new StartPageModel(item), this.header);
    }

    public static async sendContact(restId: string, data: IContact): Promise<void> {
        await axios
            .getInstance(AXIOS_NAMES.ONEMIN)
            .post<void>(this.url(`/restaurants/${restId}/contact`), data);
        return;
    }

    public static async loadRestaurantsByAddress(
        data: IDynamicObject<any>
    ): Promise<RestaurantModel[]> {
        let query = "?";
        for (const key in data) {
            query += `${key}=${data[key]}&`;
        }
        query = query.slice(0, query.length - 1);
        const request = await axios
            .getInstance(AXIOS_NAMES.ONEMIN)
            .get<IDynamicObject<any>[]>(`${this.url(`/restaurants/find${query}`, true)}`, data);
        return orderBy(
            request.data.map((item) => new RestaurantModel(item)),
            "name",
            "asc"
        );
    }

    public static async loadRegions(value: string): Promise<RegionModel[]> {
        const request = await axios
            .getInstance(AXIOS_NAMES.ONEMIN)
            .get<IDynamicObject<any>[]>(
                this.url(`/restaurants/delivery-regions?name_filter=${value}`, true)
            );
        return request.data.map((item) => new RegionModel(item));
    }

    public static async loadStreets(
        restId: string,
        regionId: string,
        name: string
    ): Promise<StreetModel[]> {
        const request = await axios
            .getInstance(AXIOS_NAMES.ONEMIN)
            .get<IDynamicObject<any>[]>(
                this.url(
                    `/restaurants/${restId}/delivery-regions/${regionId}/streets?name=${name}`,
                    true
                )
            );
        const array = [];
        for (const item of request.data) {
            if (!item.active || item.archived_at) continue;
            array.push(new StreetModel(item));
        }
        return array;
    }
}
