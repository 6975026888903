import WidgetNavbar from "./Components/Widgets/Navbar/View/Navbar.index";
import WidgetFooter from "./Components/Widgets/Footer/View/Footer.view";
import { FC, useMemo, useRef, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useDidMount, useTimeout } from "xa-generics";
import { useTranslation } from "react-i18next";
import { DomMapperView } from "./Components/DomMapper/View/DomMapper.view";
import { PageDataModel } from "./Models/PageData.model";
import { useGlobal } from "./Provider/GlobalContext.provider";
import { ILang } from "./Interfaces/ILang.type";
import { map } from "lodash";

interface IPartnerRoutesProps {}

export const PartnerRoutes: FC<IPartnerRoutesProps> = (props) => {
    const { i18n } = useTranslation<ILang>();
    const { setTm } = useTimeout();
    const global = useGlobal();

    const footRef = useRef<HTMLElement | null>(null);
    const [spacing, setSpacing] = useState<number>(400);

    const rootPage = useMemo(() => {
        let mainPage: PageDataModel | null = null;
        let selectorPage!: PageDataModel;
        for (const page of global.pages) {
            if (global.showOnlySelector && page.page_type === "index") {
                return page;
            }
            if (page.page_type === "index") selectorPage = page;
            if (page.is_main_page) mainPage = page;
        }
        return mainPage || selectorPage;
    }, [global.pages, global.showOnlySelector]);

    const handle = () => {
        setTm(() => {
            if (!footRef.current) return;
            return setSpacing(footRef.current.clientHeight);
        }, 300);
    };

    useDidMount(() => {
        setTm(() => {
            if (!footRef.current) return;
            return setSpacing(footRef.current.clientHeight);
        }, 300);

        window.addEventListener("resize", handle, { passive: true });
        return () => {
            window.removeEventListener("resize", handle);
        };
    });

    const style: React.CSSProperties = {
        backgroundColor: global.config.body_bg_color || undefined,
        color: global.config.body_color || undefined,
        backgroundImage: global.images[global.config.body_bg_image_id]?.url
            ? `url(${global.images[global.config.body_bg_image_id]!.url})`
            : undefined,
        backgroundSize: global.config.body_bg_image_size || undefined,
        backgroundAttachment: global.config.body_bg_image_attach || undefined,
        backgroundPosition: global.config.body_bg_image_position || undefined,
        backgroundRepeat: global.config.body_bg_repeat ? "repeat" : "no-repeat"
    };

    const classes = ["page-container", `page-${global.size}`, global.currentFont];
    if (global.config.is_dark_theme) classes.push("dark-theme");
    if (global.config.is_rounded_btn) classes.push("rounded-corners");

    return (
        <>
            <div id={"PAGE_CONTAINER"} style={style} className={classes.join(" ")}>
                <WidgetNavbar />

                <Routes>
                    {map(global.pages, (page) => {
                        const url = page.getSeo(i18n.language).url;
                        return (
                            <Route
                                path={url}
                                key={page.page_id}
                                element={<DomMapperView page={page} />}
                            />
                        );
                    })}
                    <Route
                        path="*"
                        key={"redirect-page"}
                        element={<Navigate to={rootPage.getSeo(i18n.language).url} replace />}
                    />
                </Routes>
                {global.showOnlySelector || global.widgets.Footer.settings.isHidden ? null : (
                    <>
                        <div style={{ paddingBottom: `${spacing}px` }} className="global__spacer" />
                        <WidgetFooter footRef={footRef} />
                    </>
                )}
            </div>
        </>
    );
};
